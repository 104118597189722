import React from 'react';

const Logo = props => {
  return (
    <img
      alt="Logo"
      style={{ width: '100%' }}
      src="/static/icons/logo.svg"
      {...props}
    />
  );
};

export default Logo;
