import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const useStyles = makeStyles(theme => ({
  icon: {
    width: '9px',
    height: '9px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    backgroundImage: 'url(/static/icons/copy.svg)',
    cursor: 'pointer',
    opacity: 0.5,
    margin: '8px',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.56rem',
      height: '0.56rem',
      margin: '0.56rem',
    },

    '&:hover': {
      opacity: 1,
    },
    '&:hover + div': {
      display: 'block !important',
    },
  },
}));

export default function ({ value = '' }) {
  const classes = useStyles();
  const [isCopied, setIsCopied] = useState('Copy');

  return (
    <div className="relative w-full">
      <input
        className="w-full"
        style={{
          padding: '2px',
          paddingRight: '36px',
          borderRadius: '5px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          outline: 'none',
          border: 0,
          textOverflow: 'ellipsis',
          fontSize: '0.7rem',
        }}
        value={value}
        readOnly
      />
      <CopyToClipboard text={value}>
        <div
          className={clsx('absolute right-0 top-0', classes.icon)}
          onMouseEnter={() => setIsCopied('Copy')}
          onClick={() => setIsCopied('Copied!')}
        />
      </CopyToClipboard>
      <div
        style={{
          position: 'absolute',
          display: 'none',
          top: '-37px',
          right: '13px',
          background: '#363636',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
          transform: 'translate(50%, 0px)',
        }}
      >
        {isCopied}
        <div
          style={{
            position: 'absolute',
            top: '34px',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid #363636',
            left: '50%',
            transform: 'translateX(-50%)',
            pointerEvents: 'none',
          }}
        ></div>
      </div>
    </div>
  );
}
