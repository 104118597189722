import React, { useMemo, useEffect, useRef, useState } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useAsync } from 'react-use';
import { Bar, Line } from 'react-chartjs-2';
import { Link, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';
import { filterStatsByDate } from '~/views/private/overview/utils';

import {
  getCompanyLocationStats,
  // getCompanyLocations,
  // getAllMultiLocations,
  getAllVisitsStats,
  getAllViewDurationStats,
  getAllStats,
  getCompanyViewDurationStats,
  getCompanyVisitsStats,
  getCompanyFullStats,
  getCompaniesFullStats,
} from '~/services/api';
import { authRoles } from '~/constants';
import * as allMultitourStatsStore from '~/store/allMultitourStats';

import { formatDateFromSeconds } from '~/utils/helper';
import { getShortNumber } from '~/utils/getShortNumber';
import CustomDateRangePicker from '~/views/private/overview/Components/DateRangePicker';
import size from '~/utils/size';
import { defaultTimeRange } from '~/utils/defaultTimeRange';

import ViewsByDevices from './Components/ViewsByDevices';
import debounce from '~/utils/debounce';
import getTooltipValue from '~/utils/getTooltipValue';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '44px',
    paddingBottom: '0px',
  },
  backIcon: {
    marginRight: '14px',
    display: 'block',
    width: '25px',
    height: '24px',
    backgroundImage: 'url(/static/icons/arrow-back.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    cursor: 'pointer',

    '&:hover': {
      backgroundImage: 'url(/static/icons/arrow-back-active.svg)',
    },
  },
  infoCard: {
    cursor: 'pointer',
    '&:hover>div': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
  },
  popularTourCard: {
    padding: '6px',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
  },
  tooltip: {
    position: 'absolute',
    left: '0',
    backgroundColor: '#333',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '4px',
    transform: 'translate(6px, 8px)',
    visibility: 'hidden',
    padding: '6px',
    zIndex: 1,
    whiteSpace: 'nowrap',

    [theme.breakpoints.up('hiRes')]: {
      transform: 'translate(1rem, 1.25rem)',
      padding: '0.375rem',
    },
  },
  tooltipArrow: {
    width: '0px',
    height: '0px',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid #2f2f2f',
    position: 'absolute',
    top: '-5px',
    left: '0',
    transform: 'translateX(50%)',

    [theme.breakpoints.up('hiRes')]: {
      borderLeft: '0.3125rem solid transparent',
      borderRight: '0.3125rem solid transparent',
      borderBottom: '0.3125rem solid #2f2f2f',
      top: '-0.28rem',
    },
  },
  unpaidImg: {
    '&:hover + span': {
      visibility: 'visible',
    },
  },
  paidItem: {
    cursor: 'default',
    '& .tooltip': {
      position: 'absolute',
      left: '0',
      backgroundColor: '#333',
      color: '#fff',
      textAlign: 'center',
      borderRadius: '4px',
      transform: 'translate(30px, 20px)',
      visibility: 'hidden',
      padding: '6px',

      [theme.breakpoints.up('hiRes')]: {
        transform: 'translate(1.875rem, 1.25rem)',
        padding: '0.375rem',
      },
    },
    '& .tooltip>.tooltip-arrow': {
      width: '0px',
      height: '0px',
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderBottom: '5px solid #2f2f2f',
      position: 'absolute',
      top: '-5px',
      left: '0',
      transform: 'translateX(50%)',

      [theme.breakpoints.up('hiRes')]: {
        borderLeft: '0.3125rem solid transparent',
        borderRight: '0.3125rem solid transparent',
        borderBottom: '0.3125rem solid #2f2f2f',
        top: '-0.28rem',
      },
    },
    '&:hover .scroll': {
      visibility: 'visible',
    },
  },
  upload: {
    background: 'rgba(54, 166, 253, 0.15)',
    borderRadius: 6,
    padding: '3px 4px',
    width: '24px',
    display: 'flex',
    marginLeft: 6,
    height: '24px',
    cursor: 'pointer',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.4rem',
      height: '1.4rem',
      padding: '0.28rem',
    },
  },
  header: {
    fontSize: '1.5rem',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1.33rem',
    },
  },
  header2: {
    fontSize: '0.875rem',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '1rem',
    },
  },
  mostPopularTours: {
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',

    [theme.breakpoints.up('hiRes')]: {
      padding: '0.6rem 0.9rem 1rem',
    },
  },
  popularImg: {
    borderRadius: '10px',
    height: '64px',

    [theme.breakpoints.up('hiRes')]: {
      height: '3.8rem',
    },
  },
  h12: {
    height: '270px',

    [theme.breakpoints.up('hiRes')]: {
      height: '12vw',
    },
  },
  h14: {
    height: '330px',

    [theme.breakpoints.up('hiRes')]: {
      height: '14vw',
    },
  },
  paymentContainer: {
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
    // maxHeight: '690px',
    height: '0px',

    [theme.breakpoints.up('hiRes')]: {
      padding: '0.6rem 1.2rem',
    },
  },
  activeNumber: {
    transform: 'translateX(-50%)',
    bottom: '4px',
    pointerEvents: 'none',
    height: '12px',
    color: '#36A6FD',
    fontSize: '0.625rem',

    [theme.breakpoints.up('hiRes')]: {
      buttom: '0.25rem',
      height: '0.75rem',
    },
  },
  smallTooltip: {
    borderRadius: '3px',
    transform: 'translate(-50%, -135%)',
    width: '50px',
    height: '30px',
    background: '#36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      borderRadius: '0.188rem',
      width: '3.125rem',
      height: '1.875rem',
    },
  },
  smallTriangle: {
    position: 'absolute',
    top: '20px',
    borderLeft: '18px solid transparent',
    borderRight: '18px solid transparent',
    borderTop: '16px solid #36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      top: '1.25rem',
      borderLeft: '1.125rem solid transparent',
      borderRight: '1.125rem solid transparent',
      borderTop: '1rem solid #36A6FD',
    },
  },
  bigCircle: {
    width: '20px',
    height: '20px',
    background: 'rgb(54, 166, 253, 0.2)',
    top: '40px',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.25rem',
      height: '1.25rem',
      top: '2.5rem',
    },
  },
  mediumCircle: {
    width: '14px',
    height: '14px',
    background: 'rgb(54, 166, 253, 0.4)',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.875rem',
      height: '0.875rem',
    },
  },
  smallCircle: {
    width: '7px',
    height: '7px',
    background: '#36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.5rem',
      height: '0.5rem',
    },
  },
  value: {
    fontSize: '1.25rem',
    lineHeight: '2rem',

    [theme.breakpoints.up('lowRes')]: {
      fontSize: '1.5rem',
    },
  },
}));

const charts = {
  VISITS: 'Visits',
  VIEW_DURATION: 'View Duration',
};

function checkOverflow(id) {
  const link = document.getElementById(`payment-status-link-${id}`);
  if (link.offsetWidth < link.scrollWidth) {
    const tooltip = document.getElementById(`payment-status-tooltip-${id}`);
    tooltip.classList.add('scroll');
  }
}

function deleteClass(id) {
  const tooltip = document.getElementById(`payment-status-tooltip-${id}`);
  tooltip.classList.remove('scroll');
}

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    margin: 10,
  },
}));

function InfoCard({ className = '', title, value, fullTitle }) {
  const classes = useStyles();
  const tooltipClass = useTooltipStyles();

  return (
    <Tooltip
      classes={tooltipClass}
      placement="top"
      title={`${fullTitle || title}: ${getTooltipValue(value)}`}
    >
      <div className={clsx('flex-auto mb-3', classes.infoCard, className)}>
        <div
          className="w-full bg-white flex flex-col justify-center rounded-lg"
          style={{
            padding: '0.625rem',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          {title}
          <span className={`font-bold ${classes.value}`}>
            {getShortNumber(value)}
          </span>
        </div>
      </div>
    </Tooltip>
  );
}

const ctx = document.createElement('canvas').getContext('2d');
const gradient = ctx.createLinearGradient(0, 0, 0, 250);
gradient.addColorStop(0, 'rgba(54,166,253,0.6)');
gradient.addColorStop(1, 'rgba(54,166,253,0)');

const viewDurationGradient = ctx.createLinearGradient(0, 0, 0, 200);
viewDurationGradient.addColorStop(0, 'rgba(54,166,253,0.5)');
viewDurationGradient.addColorStop(1, 'rgba(54,166,253,0.25)');

const viewDurationGradientHover = ctx.createLinearGradient(0, 0, 0, 200);
viewDurationGradientHover.addColorStop(0, 'rgba(54,166,253,1)');
viewDurationGradientHover.addColorStop(1, 'rgba(54,166,253,0.49)');

function ViewDurationChart() {
  const refDuration = useRef();
  const classes = useStyles();

  const user = useSelector(state => state.auth.user);
  const isAdmin = user.role === authRoles.ADMIN;

  const [isTitles, setIsTitles] = useState({});

  const [viewDurationChartTooltip, setViewDurationChartTooltip] =
    React.useState({
      caretX: 0,
      caretY: 0,
      scrollX: 0,
      show: false,
      value: 0,
      label: 0,
    });

  const [viewDurationDateValue, setViewDurationDateValue] =
    React.useState(defaultTimeRange);

  const viewDurationStats = useAsync(async () => {
    try {
      if (isAdmin) return await getAllViewDurationStats();
      return await getCompanyViewDurationStats(user.company);
    } catch (e) {
      console.error('overview:viewDurationStats', e);
      return [];
    }
  });

  if (!viewDurationStats.value) {
    return null;
  }

  const filteredViewDurationStats = filterStatsByDate(
    viewDurationStats,
    viewDurationDateValue,
  ).slice();

  const debouncedHover = (e, [chart]) => {
    if (!chart) {
      if (!viewDurationChartTooltip.show) return;

      setViewDurationChartTooltip({
        ...viewDurationChartTooltip,
        show: false,
      });

      return;
    }

    if (viewDurationChartTooltip.label === chart._xScale.ticks[chart._index])
      return;

    return setViewDurationChartTooltip({
      show: true,
      scrollX: e.target.parentElement.parentElement.scrollLeft,
      offsetY: e.target.parentElement.parentElement.offsetTop,
      caretX: chart._view.x,
      caretY: chart._view.y,
      value: `${Math.round(
        filteredViewDurationStats[chart._index].duration / 60,
      )}m`,
      label: chart._xScale.ticks[chart._index],
    });
  };

  return (
    <>
      <div
        className="font-bold bg-white flex justify-between"
        style={{
          padding: '18px 0',
        }}
      >
        <div
          className="flex"
          style={{
            marginBottom: 18,
            fontSize: '0.75rem',
            alignItems: 'center',
          }}
        >
          View Duration
          <div
            className={classes.upload}
            onClick={() =>
              setIsTitles({
                canvas: refDuration.current.chartInstance.canvas,
                chart: charts.VIEW_DURATION,
              })
            }
          >
            <img src="/static/icons/upload.svg" alt="Upload" />
          </div>
        </div>

        <CustomDateRangePicker
          value={viewDurationDateValue}
          onChange={setViewDurationDateValue}
        />
      </div>
      <div
        className={'w-full relative flex flex-col ' + classes.h14}
        style={{
          overflow: 'auto',
        }}
      >
        <div
          style={{
            position: 'relative',
            height: '100%',
            minWidth: filteredViewDurationStats.length * size(32),
          }}
        >
          <Bar
            ref={refDuration}
            data={{
              labels: filteredViewDurationStats.map(item =>
                moment(item.eventDate).format('MM.DD'),
              ),
              datasets: [
                {
                  backgroundColor: viewDurationGradient,
                  hoverBackgroundColor: viewDurationGradientHover,
                  data: filteredViewDurationStats.map(
                    item => item.duration / 60,
                  ),
                  label: '',
                  datasetLabel: '',
                },
              ],
            }}
            options={{
              animation: false,
              cornerRadius: 20,
              layout: { padding: 0 },
              legend: { display: false },
              maintainAspectRatio: false,
              responsive: true,

              onHover: debouncedHover,
              tooltips: {
                position: 'nearest',
                enabled: false,
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      fontSize: size(8),
                      reverse: true,
                    },
                    categoryPercentage: 0.97,
                    barPercentage: 0.97,
                    barThickness: 28,
                    scaleLabel: {
                      display: isTitles.chart === charts.VIEW_DURATION,
                      labelString: 'Date',
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      fontStyle: 'bold',
                      fontColor: '#000',
                      stepSize: 250,
                    },
                    scaleLabel: {
                      display: isTitles.chart === charts.VIEW_DURATION,
                      labelString: 'Time (m)',
                    },
                  },
                ],
              },
            }}
          />
        </div>

        <span
          className={'absolute bg-white font-bold ' + classes.activeNumber}
          style={{
            position: 'absolute',
            left: 0,
            opacity: viewDurationChartTooltip.show ? 1 : 0,
            transform: `translate3d(calc(${viewDurationChartTooltip.caretX}px - 50%), 0, 0)`,
          }}
        >
          {viewDurationChartTooltip.label}
        </span>
      </div>
      <div
        className={
          'absolute inset-0 font-bold text-xs text-white flex justify-center items-center ' +
          classes.smallTooltip
        }
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          opacity: viewDurationChartTooltip.show ? 1 : 0,

          transform: `translate3d(calc(${
            viewDurationChartTooltip.caretX -
            viewDurationChartTooltip.scrollX +
            16
          }px), calc(${
            viewDurationChartTooltip.caretY +
            (viewDurationChartTooltip.offsetY || 0) -
            10
          }px - 100%), 0)`,
          // left:
          //   viewDurationChartTooltip.caretX -
          //   viewDurationChartTooltip.scrollX +
          //   40,
          // top:
          //   viewDurationChartTooltip.caretY +
          //   (viewDurationChartTooltip.offsetY || 0),
          fontSize: '0.75rem',
          transition: 'opacity, transform 0.1s ease',
        }}
      >
        <div className={classes.smallTriangle} />
        <span className="relative">{viewDurationChartTooltip.value}</span>
      </div>
    </>
  );
}

const OverviewView = () => {
  const dispatch = useDispatch();
  const refVisits = useRef();

  const secondColRef = useRef();
  const loaded = useSelector(state => state.allMultitourStats.loaded);
  const allMultitourStats = useSelector(state => state.allMultitourStats.value);
  const allMultitourStatsLoaded = useSelector(
    state => state.allMultitourStats.loaded,
  );
  const [visitsDateValue, setVisitsDateValue] =
    React.useState(defaultTimeRange);
  const [isTitles, setIsTitles] = useState({});

  const [visitChartTooltip, setVisitChartTooltip] = React.useState({
    caretX: 0,
    caretY: 0,
    offsetY: 0,
    scrollX: 0,
    show: false,
    value: 0,
    label: 0,
  });

  const [isLoad, setIsLoad] = React.useState({});

  const classes = useStyles();

  useEffect(() => {
    if (!isTitles.chart) return;
    const { canvas, chart } = isTitles;
    setTimeout(() => {
      const imgData = canvas.toDataURL('image/png', 1);
      const maxWidth = 750;
      const maxHeight = 500;
      const ratioOfList = 210 / 297;
      const ratio = canvas.height / canvas.width;
      const docDefinition =
        ratio < ratioOfList
          ? {
              content: [
                {
                  text: chart,
                  style: 'header',
                },
                {
                  image: imgData,
                  width: maxWidth,
                  height: maxWidth * ratio,
                },
              ],
              styles: {
                header: {
                  fontSize: 35,
                  bold: true,
                },
              },
              pageOrientation: 'landscape',
            }
          : {
              content: [
                {
                  text: chart,
                  style: 'header',
                },
                {
                  image: imgData,
                  width: maxHeight / ratio,
                  height: maxHeight,
                },
              ],
              styles: {
                header: {
                  fontSize: 40,
                  bold: true,
                },
              },
              pageOrientation: 'landscape',
            };

      pdfMake.createPdf(docDefinition).download();
      setIsTitles({});
    }, 0);
  }, [isTitles]);

  const user = useSelector(state => state.auth.user);
  // const allStats = useAsync(getAllStats, []);
  // const { role } = user;

  const isAdmin = user.role === authRoles.ADMIN;

  useEffect(() => {
    if (!loaded) {
      (isAdmin ? getCompaniesFullStats() : getCompanyFullStats(user.company))
        .then(result => dispatch(allMultitourStatsStore.load(result)))
        .catch(console.error);
    }
  }, [dispatch, isAdmin, loaded, user.company]);

  const stats = useAsync(async () => {
    try {
      if (isAdmin) return await getAllStats();
      return await getCompanyLocationStats(user.company);
    } catch (e) {
      console.error('overview:stats', e);

      return {
        totalVisitsMobile: 0,
        totalVisits: 1,
        totalCustomButtonClicks: 0,
        avgVisitsPerDay: 0,
        avgVisitsPerMonth: 0,
        totalDuration: 1,
        avgVisitDuration: 1,
      };
    }
  });

  // const locationsForUser = useAsync(() => buildLocationsForUser(user.company), [
  //   user.company,
  // ]);

  // const locationsData = useAsync(async () => {
  //   try {
  //     if (isAdmin) {
  //       return await getAllMultiLocations().then(x =>
  //         x.flatMap(v => v.multilocations),
  //       );
  //     }
  //     return await getCompanyLocations(user.company);
  //   } catch (e) {
  //     console.error('overview:locationsData');
  //     return [];
  //   }
  // });

  // const locations = useMemo(() => {
  //   return uniq(
  //     (locationsData.value || [])
  //       .filter(v => v && v != 'undefined' && v != 'null')
  //       .map(v => v.toLowerCase()),
  //   );
  // }, [locationsData]);

  // const multiLocationStatsData = useAsync(() => {
  //   const requests = [];
  //   if (locations.length) {
  //     locations.forEach(listId =>
  //       requests.push(
  //         getMultiLocationStats(listId, user.company).then(
  //           async multiLocation => {
  //             const locationsViaList = await getLocationsViaList(listId);

  //             const mainLocation =
  //               locationsViaList.find(
  //                 v => v.is_main === 1 && v.company_name === user.company,
  //               ) ||
  //               locationsViaList.find(v => v.company_name === user.company) ||
  //               locationsViaList[0];

  //             return {
  //               ...multiLocation,
  //               listId,
  //               tourId: mainLocation.id,
  //               companyName: mainLocation.company_name,
  //             };
  //           },
  //         ),
  //       ),
  //     );

  //     return Promise.all(requests);
  //   }
  //   return Promise.resolve();
  // }, [locations]);

  // const { value: allMultitourStats = [] } = allMultitourStatsData || {};

  // const multiLocationStats = useMemo(() => {
  //   return multiLocationStatsData.value || [];
  // }, [multiLocationStatsData]);

  const mostPopularTours = allMultitourStats.slice(0, 2);

  const multitoursPaidStatusList = useMemo(() => {
    return allMultitourStats.map(({ multilocationName, mainLocation }) => ({
      multilocationName,
      mainLocation,
      paymentDetails: mainLocation.description.IS_PAID
        ? {
            isPaid: true,
            startDate: mainLocation.description.CREATED_AT,
            dueDate: mainLocation.description.CREATED_AT
              ? moment(mainLocation.description.CREATED_AT)
                  .add(1, 'year')
                  .format('YYYY-MM-DD')
              : '',
          }
        : { isPaid: false },
    }));
  }, [allMultitourStats]);

  const conversion = allMultitourStats.reduce(
    (acc, tour) => acc + (tour.stats.totalHotspotButtonClicks || 0),
    0,
  );

  const visitStats = useAsync(async () => {
    try {
      if (isAdmin) return await getAllVisitsStats();
      return await getCompanyVisitsStats(user.company);
    } catch (e) {
      console.error('overview:visitStats', e);
      return [];
    }
  });

  if (!stats.value || !visitStats.value) {
    return null;
  }

  const mobileVisitsPercent =
    (100 * stats.value.totalVisitsMobile) /
    (stats.value.totalVisitsMobile + stats.value.totalVisits);

  const desktopVisitsPercent = 100 - mobileVisitsPercent;

  const filteredVisitStats = filterStatsByDate(
    visitStats,
    visitsDateValue,
  ).slice();

  return (
    <Page className={classes.root} title="Overview">
      <Header>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '26px',
          }}
        >
          <div>
            <div className={classes.header}>OVERVIEW</div>
            <div className={classes.header2}>Multi-Tour Analytics</div>
          </div>
        </div>
      </Header>
      {/* <div className="w-full flex mb-3 mt-5">
        <div className="lg:w-5/12" />
        <div
          className="lg:w-7/12"
          style={{ paddingLeft: '50px', display: 'flex' }}
        >
          <div
            style={{
              padding: '10px 10px',
              backgroundColor: '#36A6FD',
              borderRadius: '5px',
              color: '#FFFFFF',
            }}
          >
            ALL TOURS ANALYTICS
          </div>
        </div>
      </div> */}

      <div
        className="w-full flex flex-col lg:flex-row"
        style={{ minHeight: '100vh' }}
      >
        <div
          className="w-full lg:w-5/12 lg:pr-3 flex flex-col"
          style={{
            height: secondColRef.current && secondColRef.current.clientHeight,
          }}
        >
          <div
            className="w-full flex flex-wrap justify-between mb-1"
            style={{ width: 'calc(100% + 8px)', marginLeft: '-4px' }}
          >
            <InfoCard
              className="px-1"
              title="Custom Button Clicks "
              value={stats.value.totalCustomButtonClicks}
            />
            <InfoCard className="px-1" title="Conversion " value={conversion} />
          </div>

          <div className={'w-full bg-white ' + classes.mostPopularTours}>
            <div
              className="flex font-bold"
              style={{ color: '#6C6C6C', marginBottom: '22px' }}
            >
              <div className="w-1/2 font-bold" style={{ fontSize: '0.875rem' }}>
                Most Popular Tours
              </div>
              <div
                className="w-1/4 text-center"
                style={{ fontSize: '0.6875rem' }}
              >
                Visits
              </div>
              <div
                className="w-1/4 text-center"
                style={{ fontSize: '0.6875rem' }}
              >
                View Duration
              </div>
            </div>

            {!allMultitourStatsLoaded && <Skeleton height={162} />}

            {mostPopularTours.map(tour => {
              return (
                <NavLink
                  to={
                    tour.isRealMultitour
                      ? `/overview/multi/${tour.companyName}/${tour.multilocationName}`
                      : `/overview/location/${tour.id}`
                  }
                  key={tour.tourId + tour.multilocationName}
                  className={clsx('flex items-center', classes.popularTourCard)}
                  style={{ marginBottom: '10px' }}
                >
                  <div className="flex w-1/2 font-bold uppercase items-center">
                    <div
                      className="w-1/2 flex-shrink-0"
                      style={{
                        maxWidth: 150,
                        marginRight: '20px',
                        borderRadius: '10px',
                        flexShrink: 0,
                      }}
                    >
                      {!isLoad[tour.id] && <Skeleton height={64} />}
                      <img
                        className={classes.popularImg}
                        style={{
                          display: isLoad[tour.id] ? 'inline' : 'none',
                        }}
                        src={`${process.env.SNOWPACK_PUBLIC_CDN_ENDPOINT}/locations/${tour.mainLocation.id}/preview.jpg`}
                        alt=""
                        onLoad={() =>
                          setIsLoad(prev => ({ ...prev, [tour.id]: true }))
                        }
                      />
                    </div>
                    <div>{tour.multilocationName}</div>
                  </div>
                  <div
                    className="w-1/4 text-center font-bold"
                    style={{ color: '#A0A0A0', fontSize: '1.125rem' }}
                  >
                    {tour.stats.totalVisits + tour.stats.totalVisitsMobile}
                  </div>
                  <div
                    className="w-1/4 text-center font-bold"
                    style={{ color: '#A0A0A0', fontSize: '1.125rem' }}
                  >
                    {formatDateFromSeconds(tour.stats.totalDuration)}
                  </div>
                </NavLink>
              );
            })}
          </div>

          <div
            className={
              'transform-gpu w-full bg-white mt-4 flex-1 mb-3 lg:mb-0 flex flex-col relative ' +
              classes.paymentContainer
            }
          >
            <div className="w-1/2 font-bold" style={{ fontSize: '0.875rem' }}>
              Payment status
            </div>

            <div
              className="flex font-bold"
              style={{
                color: '#6C6C6C',
                marginTop: '10px',
                marginBottom: '22px',
              }}
            >
              <div className="w-1/4" style={{ fontSize: '0.6875rem' }}>
                Tour name
              </div>
              <div className="w-1/4 pl-4" style={{ fontSize: '0.6875rem' }}>
                Status
              </div>
              <div className="w-1/2 flex">
                <div
                  className="w-1/3 text-center"
                  style={{ fontSize: '0.6875rem' }}
                >
                  Start Date
                </div>
                <div
                  className="w-1/3 text-center"
                  style={{ fontSize: '0.6875rem' }}
                >
                  Due Date
                </div>
                <div
                  className="w-1/3 text-center"
                  style={{ fontSize: '0.6875rem' }}
                >
                  Type
                </div>
              </div>
            </div>

            {multitoursPaidStatusList.length === 0 && (
              <Skeleton height="100%" />
            )}

            <div style={{ overflow: 'auto' }}>
              {multitoursPaidStatusList.map((tour, idx) => (
                <div
                  key={tour.mainLocation.id}
                  className="flex"
                  style={{
                    color: '#6C6C6C',
                    marginTop: idx === 0 ? '0px' : '10px',
                  }}
                >
                  <div
                    className={clsx(
                      'w-1/4 font-bold truncate',
                      classes.paidItem,
                    )}
                    style={{ color: '#333', fontSize: '0.6875rem' }}
                    onMouseEnter={() => checkOverflow(idx)}
                    onMouseLeave={() => deleteClass(idx)}
                  >
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                      <span style={{ marginRight: '10px' }}>{idx + 1}</span>{' '}
                      <Link
                        style={{
                          overflow: 'hidden',
                          display: 'block',
                          textOverflow: 'ellipsis',
                        }}
                        id={`payment-status-link-${idx}`}
                        to={`/overview/multi/${tour.mainLocation.company_name}/${tour.multilocationName}`}
                      >
                        {tour.multilocationName}
                      </Link>
                    </div>
                    <span
                      className="tooltip"
                      id={`payment-status-tooltip-${idx}`}
                    >
                      <div className="tooltip-arrow" />
                      {tour.multilocationName}
                    </span>
                  </div>
                  <div
                    className="w-1/4 pl-4 flex justify-start items-center font-bold"
                    style={{ fontSize: '0.6875rem' }}
                  >
                    {tour.paymentDetails.isPaid ? (
                      <div style={{ color: '#29CC97' }}>Paid</div>
                    ) : (
                      <div style={{ color: '#E74C3C' }}>Unpaid</div>
                    )}
                    <div style={{ position: 'relative' }}>
                      <img
                        style={{
                          marginLeft: '10px',
                          width: '0.75rem',
                          height: '0.75rem',
                        }}
                        src={`/static/icons/${
                          tour.paymentDetails.isPaid ? 'payed' : 'unpaid'
                        }.svg`}
                        alt={tour.paymentDetails.isPaid ? 'Paid' : 'Unpaid'}
                        className={classes.unpaidImg}
                      />
                      <span className={classes.tooltip}>
                        <div className={classes.tooltipArrow} />
                        Tour payment status
                      </span>
                    </div>
                  </div>
                  <div className="w-1/2 flex">
                    <div
                      className="w-1/3 text-center"
                      style={{ fontSize: '0.6875rem' }}
                    >
                      {tour.paymentDetails.isPaid
                        ? tour.paymentDetails.startDate
                        : ''}
                    </div>
                    <div
                      className="w-1/3 text-center"
                      style={{ fontSize: '0.6875rem' }}
                    >
                      {tour.paymentDetails.isPaid
                        ? tour.paymentDetails.dueDate
                        : ''}
                    </div>
                    <div
                      className="w-1/3 text-center"
                      style={{ fontSize: '0.6875rem' }}
                    >
                      {tour.paymentDetails.isPaid ? 'Annually' : ''}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="w-full lg:w-7/12 flex flex-col" ref={secondColRef}>
          <div
            className="w-full flex flex-wrap justify-between mb-1"
            style={{ width: 'calc(100% + 8px)', marginLeft: '-4px' }}
          >
            <InfoCard
              className="px-1"
              title="Visits"
              value={
                (stats.value.totalVisits || 0) +
                (stats.value.totalVisitsMobile || 0)
              }
            />
            <InfoCard
              className="px-1"
              title="Avg. Visits Per Day"
              fullTitle="Average Visits Per Day"
              value={stats.value.avgVisitsPerDay || 0}
            />
            <InfoCard
              className="px-1"
              title="Avg. Visits Per Month"
              fullTitle="Average Visits Per Month"
              value={Math.round(stats.value.avgVisitsPerMonth) || 0}
            />
            <InfoCard
              className="px-1"
              title="Duration"
              value={formatDateFromSeconds(stats.value.totalDuration)}
            />
            <InfoCard
              className="px-1"
              title="Avg. Visit Duration"
              fullTitle="Average Visit Duration"
              value={formatDateFromSeconds(stats.value.avgVisitDuration)}
            />
          </div>

          <div
            style={{
              position: 'relative',
              borderRadius: '10px',
              padding: '0 40px',
              paddingRight: '60px',
              paddingBottom: 20,
              background: 'white',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: 'space-around',
            }}
          >
            <div
              className="font-bold bg-white flex justify-between"
              style={{
                padding: '18px 0',
              }}
            >
              <div
                className="flex"
                style={{
                  marginBottom: 18,
                  fontSize: '0.75rem',
                  alignItems: 'center',
                }}
              >
                Visits
                <div
                  className={classes.upload}
                  onClick={() =>
                    setIsTitles({
                      canvas: refVisits.current.chartInstance.canvas,
                      chart: charts.VISITS,
                    })
                  }
                >
                  <img src="/static/icons/upload.svg" alt="Upload" />
                </div>
              </div>

              <CustomDateRangePicker
                value={visitsDateValue}
                onChange={setVisitsDateValue}
              />
            </div>
            <div
              className={'w-full relative flex flex-col ' + classes.h12}
              style={{
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  height: '100%',
                  minWidth: filteredVisitStats.length * size(32),
                }}
              >
                <Line
                  ref={refVisits}
                  data={{
                    datasets: [
                      {
                        backgroundColor: gradient,
                        lineTension: 0.01,
                        borderColor: '#36A6FD',
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent',
                        pointRadius: size(30),
                        pointHoverRadius: size(30),
                        borderWidth: 1,
                        data: filteredVisitStats.map(item => item.eventsCount),
                        label: '',
                      },
                    ],
                    labels: filteredVisitStats.map(item =>
                      moment(item.eventDate).format('MM.DD'),
                    ),
                  }}
                  options={{
                    animation: false,
                    cornerRadius: size(20),
                    layout: {
                      padding: {
                        right: size(13),
                      },
                    },
                    legend: { display: false },
                    maintainAspectRatio: false,
                    responsive: true,

                    onHover(e, [chart]) {
                      if (!chart) {
                        if (!visitChartTooltip.show) return;

                        setVisitChartTooltip({
                          ...visitChartTooltip,
                          show: false,
                        });
                        return;
                      }

                      if (
                        visitChartTooltip.label ===
                        chart._xScale.ticks[chart._index]
                      )
                        return;

                      return setVisitChartTooltip({
                        show: true,
                        scrollX:
                          e.target.parentElement.parentElement.scrollLeft,
                        offsetY: e.target.parentElement.parentElement.offsetTop,
                        caretX: chart._view.x,
                        caretY: chart._view.y,
                        value:
                          Math.round(
                            filteredVisitStats[chart._index].eventsCount * 10,
                          ) / 10,
                        label: chart._xScale.ticks[chart._index],
                      });
                    },
                    tooltips: {
                      enabled: false,
                      intersect: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: size(8),
                            reverse: true,
                          },
                          categoryPercentage: 0.97,
                          barPercentage: 0.97,
                          barThickness: 28,
                          scaleLabel: {
                            display: isTitles.chart === charts.VISITS,
                            labelString: 'Date',
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            fontStyle: 'bold',
                            fontColor: '#000',
                            stepSize: 250,
                          },
                          scaleLabel: {
                            display: isTitles.chart === charts.VISITS,
                            labelString: 'Visits',
                          },
                        },
                      ],
                    },
                  }}
                />
                <span
                  className={
                    'absolute bg-white font-bold ' + classes.activeNumber
                  }
                  style={{
                    position: 'absolute',
                    opacity: visitChartTooltip.show ? 1 : 0,
                    left: visitChartTooltip.caretX,
                  }}
                >
                  {visitChartTooltip.label}
                </span>
              </div>
              <div
                style={{
                  position: 'absolute',
                  opacity: visitChartTooltip.show ? 1 : 0,
                  left: visitChartTooltip.caretX - 1,
                  top: 0,
                  width: '0px',
                  borderLeft: '2px dashed rgba(255, 255, 255,1)',
                  height: 'calc(100% - 20px)',
                  background: '#36A6FD',
                  pointerEvents: 'none',
                }}
              />
            </div>
            <div
              className={
                'absolute inset-0 font-bold text-xs text-white flex justify-center items-center ' +
                classes.smallTooltip
              }
              style={{
                position: 'absolute',
                opacity: visitChartTooltip.show ? 1 : 0,
                left: visitChartTooltip.caretX - visitChartTooltip.scrollX + 40,
                top: visitChartTooltip.caretY + visitChartTooltip.offsetY - 2,
                transform: 'translate(-50%, -160%)',
                pointerEvents: 'none',
              }}
            >
              <div
                className={
                  'absolute flex justify-center items-center rounded-full ' +
                  classes.bigCircle
                }
              >
                <div
                  className={
                    'flex justify-center items-center rounded-full ' +
                    classes.mediumCircle
                  }
                >
                  <div className={'rounded-full ' + classes.smallCircle} />
                </div>
              </div>

              <div className={classes.smallTriangle} />
              <span className="relative">{visitChartTooltip.value}</span>
            </div>

            <ViewDurationChart />
          </div>
          <div className="mt-3">
            <ViewsByDevices
              // totalVisitsAllMultiLocations={totalVisitsAllMultiLocations}
              // totalVisitsMobileAllMultiLocations={
              //   totalVisitsMobileAllMultiLocations
              // }
              mobilePercent={mobileVisitsPercent}
              desktopPercent={desktopVisitsPercent}
              mobileCount={stats.value.totalVisitsMobile}
              desktopCount={stats.value.totalVisits}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default OverviewView;
