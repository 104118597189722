import { MagicTokenSingleton } from './token';

const baseUrl = process.env.CLIENT_API_URL || 'http://localhost:3000/api';

const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

async function tryFetch(route, options) {
  const res = await fetch(baseUrl + route, options);
  const body = await res.json();
  if (!res.ok) {
    throw new Error(body.message);
  }
  return body;
}

export async function login(token) {
  const req = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      ...jsonHeaders,
    },
    body: JSON.stringify({}),
  };

  try {
    const data = await tryFetch('/auth/login', req);
    return data;
  } catch (err) {
    console.error('Failed to log in', err);
    return null;
  }
}

export const FavoriteType = Object.freeze({
  LOCATION: 'location',
  TOUR: 'tour',
});

export function saveFavorite({ type, locationId, listId }) {
  return tryFetch('/favorites', {
    method: 'POST',
    headers: {
      ...jsonHeaders,
      Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}`,
    },
    body: JSON.stringify({ type, locationId, listId }),
  });
}

export async function removeFavoriteTour(listId) {
  return tryFetch(`/favorites/tours/${listId}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}` },
  });
}

export const RecentlyViewedType = Object.freeze({
  LOCATION: 'location',
  TOUR: 'tour',
});

export function getRecentlyViewed() {
  return tryFetch('/recently-viewed', {
    method: 'GET',
    headers: { Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}` },
  });
}

export function saveRecentlyViewed({ type, locationId, listId }) {
  return tryFetch('/recently-viewed', {
    method: 'POST',
    headers: {
      ...jsonHeaders,
      Authorization: `Bearer ${MagicTokenSingleton.I.getToken()}`,
    },
    body: JSON.stringify({ type, locationId, listId }),
  });
}
