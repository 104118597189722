import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  // Button,
  Card,
  CardContent,
  TextField,
  MenuItem,
  InputAdornment,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {},
  searchInput: {
    marginRight: theme.spacing(1),
  },
  companySelect: {
    marginLeft: theme.spacing(1),
  },
}));

const Toolbar = ({
  className,
  onSearchInput,
  onCompanySelect,
  search,
  company,
  companies,
  showCompanySelect,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {/* <Box display="flex" justifyContent="flex-end"> */}
      {/*   <Button className={classes.importButton}>Import</Button> */}
      {/*   <Button className={classes.exportButton}>Export</Button> */}
      {/*   <Button color="primary" variant="contained"> */}
      {/*     Add customer */}
      {/*   </Button> */}
      {/* </Box> */}
      <Box>
        {/* <Box mt={3}> */}
        <Card>
          <CardContent>
            <Box display="flex">
              <TextField
                className={classes.searchInput}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                label="Search"
                placeholder="Name"
                variant="outlined"
                value={search}
                onChange={event => onSearchInput(event.target.value)}
              />
              {/* </Box> */}
              {/* <Box maxWidth={500}> */}
              {showCompanySelect && (
                <TextField
                  className={classes.companySelect}
                  select
                  fullWidth
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <SvgIcon fontSize="small" color="action">
                  //         <SearchIcon />
                  //       </SvgIcon>
                  //     </InputAdornment>
                  //   ),
                  // }}
                  label="Company"
                  variant="outlined"
                  value={company}
                  onChange={event => onCompanySelect(event.target.value)}
                >
                  {[{ id: 0, name: 'All' }, ...companies].map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
