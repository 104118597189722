import { combineReducers } from 'redux';
import authReducer from './auth';
import recentlyViewedReducer from './recentlyViewed';
import galleryReducer from './gallery';
import allMultitourStatsReducer from './allMultitourStats';
import datePickerReducer from './datePicker';

export default combineReducers({
  auth: authReducer,
  recentlyViewed: recentlyViewedReducer,
  gallery: galleryReducer,
  allMultitourStats: allMultitourStatsReducer,
  datePicker: datePickerReducer,
});
