import moment from 'moment';

export function filterStatsByDate(data, date) {
  if (typeof data.value[Symbol.iterator] !== 'function') {
    return;
  }

  return (
    date[0]
      ? [...data.value].filter(
          event =>
            moment(event.eventDate).diff(date[0]) >= 0 &&
            moment(event.eventDate).diff(date[1]) <= 0,
        )
      : [...data.value]
  ).sort((a, b) => (b.eventDate > a.eventDate) - (b.eventDate < a.eventDate));
}
