import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, List, makeStyles } from '@material-ui/core';
import {
  // AlertCircle as AlertCircleIcon,
  // BarChart as BarChartIcon,
  // Lock as LockIcon,
  // Settings as SettingsIcon,
  // ShoppingBag as ShoppingBagIcon,
  // User as UserIcon,
  // UserPlus as UserPlusIcon,
  // Users as UsersIcon,
  List as ListIcon,
} from 'react-feather';
import clsx from 'clsx';
import { authRoles } from '~/constants';

import Logo from '~/components/shared/Logo';

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%',
    height: 60,
    [theme.breakpoints.up('hiRes')]: {
      height: '3.75rem',
    },
  },
  mobileDrawer: {
    width: 211,
    background: '#333',
  },
  desktopDrawer: {
    width: 211,
    background: '#333',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  navItem: {
    padding: '0 25px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.7rem',
    color: '#6C6C6C',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '0.83rem',
    },

    '&.active, &:hover': {
      color: '#fff',
    },

    '&.active': {
      background: '#6C6C6C',
    },

    '&.active > div.overview, &:hover > div.overview': {
      backgroundImage: 'url(/static/icons/overview-active.svg)',
    },

    '&.active > div.gallery, &:hover > div.gallery': {
      backgroundImage: 'url(/static/icons/gallery-active.svg)',
    },

    '&.active > div.contact, &:hover > div.contact': {
      backgroundImage: 'url(/static/icons/contact-active.svg)',
    },
  },
  navItemIcon: {
    width: '21px',
    height: '21px',
    marginRight: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.25rem',
      height: '1.25rem',
      marginRight: '1.25rem',
    },

    '&.overview': { backgroundImage: 'url(/static/icons/overview.svg)' },
    '&.gallery': { backgroundImage: 'url(/static/icons/gallery.svg)' },
    '&.contact': { backgroundImage: 'url(/static/icons/contact.svg)' },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const user = useSelector(state => state.auth.user);
  const classes = useStyles();
  const routerLocation = useLocation();

  const isAdmin = user.role === authRoles.ADMIN;

  const allMultitourStats = useSelector(state => state.allMultitourStats.value);

  const multitoursPaidStatusList = useMemo(() => {
    return allMultitourStats
      ? allMultitourStats.filter(
          ({ mainLocation }) => mainLocation.description.IS_PAID,
        )
      : [];
  }, [allMultitourStats]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerLocation.pathname]);

  const content = (
    <Box
      style={{ overflowY: 'auto', position: 'relative' }}
      flexShrink="0"
      height="100%"
      display="flex"
      bgcolor="#333333"
      alignItems="center"
      color="#fff"
      zIndex="1"
      className={classes.box}
    >
      <Box justifyContent="center" display="flex" width="20%">
        <Logo style={{ width: '4rem' }} />
      </Box>

      <Box width="80%" height="100%">
        <List
          padding="0"
          style={{
            padding: 0,
            display: 'flex',
            height: '100%',
          }}
        >
          <NavLink to="/overview" className={clsx(classes.navItem, 'overview')}>
            <div className={clsx(classes.navItemIcon, 'overview')} />
            OVERVIEW
          </NavLink>

          <NavLink to="/gallery" className={clsx(classes.navItem, 'gallery')}>
            <div className={clsx(classes.navItemIcon, 'gallery')} />
            GALLERY
          </NavLink>

          <NavLink to="/contacts" className={clsx(classes.navItem, 'contact')}>
            <div className={clsx(classes.navItemIcon, 'contact')} />
            CONTACT
          </NavLink>
        </List>
      </Box>

      {/*       <Box
        display={isAdmin ? 'none' : 'flex'}
        alignItems="center"
        marginTop="auto"
        style={{ color: '#CBC9C9', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', position: 'absolute', right: 0 }}
      >
        <img
          alt="Logo"
          style={{ width: '30px', height: '30px', marginRight: '10px' }}
          src="/static/icons/confirm.svg"
        />
        <div
          style={{ fontSize: '0.75rem', lineHeight: '1rem', fontWeight: 700, padding: '0px 10px' }}
        >
          Thank you for
          <br />
          the purchase!
        </div>
        <div style={{ fontSize: '0.625rem', fontWeight: 600, padding: '0px 10px' }}>
          You have paid {multitoursPaidStatusList.length} tours
        </div>
      </Box> */}

      {/* <Box p={2} bgcolor="background.dark">
        <List>
          {devItems.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box> */}
      {/* <Box p={2} m={2} bgcolor="background.dark"> */}
      {/*   <Typography align="center" gutterBottom variant="h4"> */}
      {/*     Test */}
      {/*   </Typography> */}
      {/*   <Typography align="center" variant="body2"> */}
      {/*     Test */}
      {/*   </Typography> */}
      {/*   <Box display="flex" justifyContent="center" mt={2}> */}
      {/*     <Button color="primary" component="a" href="#" variant="contained"> */}
      {/*       Test */}
      {/*     </Button> */}
      {/*   </Box> */}
      {/* </Box> */}
    </Box>
  );

  return content;
  // <React.Fragment>
  //   <Hidden lgUp>
  //     <Drawer
  //       anchor="left"
  //       classes={{ paper: classes.mobileDrawer }}
  //       onClose={onMobileClose}
  //       open={openMobile}
  //       variant="temporary"
  //     >
  //       {content}
  //     </Drawer>
  //   </Hidden>
  //   <Hidden mdDown>
  //     <Drawer
  //       anchor="left"
  //       classes={{ paper: classes.desktopDrawer }}
  //       open
  //       variant="persistent"
  //     >
  //       {content}
  //     </Drawer>
  //   </Hidden>
  // </React.Fragment>
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
