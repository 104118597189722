import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from '~/components/shared/Page';
// import Budget from './components/Budget';
// import LatestOrders from './components/LatestOrders';
// import LatestProducts from './components/LatestProducts';
// import Sales from './components/Sales';
import LatestLocations from './components/LatestLocations';
import LatestCompanies from './components/LatestCompanies';
import Analytics from './components/Analytics';
// import TasksProgress from './components/TasksProgress';
// import TotalCustomers from './components/TotalCustomers';
// import TotalProfit from './components/TotalProfit';
// import TrafficByDevice from './components/TrafficByDevice';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Budget />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TasksProgress />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalProfit />
          </Grid> */}
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Analytics />
          </Grid>
          {/* <Grid item lg={4} md={6} xl={3} xs={12}>
            <TrafficByDevice />
          </Grid> */}
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <LatestCompanies />
          </Grid>
          <Grid item lg={8} md={12} xl={9} xs={12}>
            <LatestLocations />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
