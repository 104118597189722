import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  // Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  row: {
    cursor: 'pointer',
  },
  table: {
    whiteSpace: 'nowrap',
  },
  nameCell: {
    width: '100%',
  },
}));

function CompanyTable({ className, companies, ...rest }) {
  const navigate = useNavigate();
  const classes = useStyles();
  // const [limit, setLimit] = useState(10);
  // const [page, setPage] = useState(0);

  // const handleLimitChange = event => {
  //   setLimit(event.target.value);
  // };

  // const handlePageChange = (event, newPage) => {
  //   setPage(newPage);
  // };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell className={classes.nameCell}>Name</TableCell>
                <TableCell>Locations</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map(company => (
                <TableRow
                  hover
                  key={company.id}
                  onClick={() => navigate(`/companies/${company.id}`)}
                  className={classes.row}
                >
                  <TableCell>{company.id}</TableCell>
                  <TableCell>
                    {/* <Box alignItems="center" display="flex"> */}
                    {/*   <Avatar */}
                    {/*     className={classes.avatar} */}
                    {/*     src={company.avatarUrl} */}
                    {/*   > */}
                    {/*     {company.name} */}
                    {/*   </Avatar> */}
                    {/* </Box> */}
                    <Typography color="textPrimary" variant="body1">
                      {company.name}
                    </Typography>
                  </TableCell>
                  <TableCell>{company.locations.length}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {/* <TablePagination */}
      {/*   component="div" */}
      {/*   count={companies.length} */}
      {/*   onChangePage={handlePageChange} */}
      {/*   onChangeRowsPerPage={handleLimitChange} */}
      {/*   page={page} */}
      {/*   rowsPerPage={limit} */}
      {/*   rowsPerPageOptions={[5, 10, 25]} */}
      {/* /> */}
    </Card>
  );
}

CompanyTable.propTypes = {
  className: PropTypes.string,
  companies: PropTypes.array.isRequired,
};

CompanyTable.defaultProps = {
  className: '',
};

export default CompanyTable;
