export class MagicTokenSingleton {
  _magic = null;

  _token = '';

  static I = new MagicTokenSingleton();

  getToken() {
    if (!this._token) {
      throw new Error('MagicTokenSingleton is not initialized');
    }
    return this._token;
  }

  async init(magic, initialToken = '') {
    if (this._magic) {
      throw new Error('MagicTokenSingleton is already initialized');
    }

    this._magic = magic;

    if (initialToken) {
      this._token = initialToken;
    } else {
      await this._fetchToken();
    }

    setInterval(async () => {
      try {
        await this._fetchToken();
      } catch (e) {
        console.error(e);
      }
    }, 10 * 60 * 1000); // 10m
  }

  _fetchToken = async () => {
    this._token = await this._magic.user.getIdToken();
  };
}
