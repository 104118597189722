import { createSlice } from '@reduxjs/toolkit';

const allMultitourStatsSlice = createSlice({
  name: 'allMultitourStats',
  initialState: { value: [], loaded: false },
  reducers: {
    load: (state, { payload }) => {
      state.loaded = true;
      state.value = payload;
    },
  },
});

export const { load } = allMultitourStatsSlice.actions;

export default allMultitourStatsSlice.reducer;
