import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { makeStyles, Button, Tooltip } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useAsync } from 'react-use';
import moment from 'moment';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import Skeleton from 'react-loading-skeleton';
import cn from 'classnames';
import getPointPreviewURL, {
  getPointCubePreviewUrl,
  getStagingPointPreviewURL,
} from '~/utils/getPointPreviewURL';
import ViewsByDevices from './Components/ViewsByDevices';

import { eventTypes } from '~/views/private/location/LocationView/components/Analytics/constants';

import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';
import {
  getStats,
  getMultiLocationStats,
  getLocationsViaList,
  getMultitourPointViewDurationStats,
  getMultitourViewDurationStats,
  getMultitourPointClicksStats,
  getMultitourVisitsStats,
  getMultitourMenuClickStats,
  getLocation,
  existsOnServer,
} from '~/services/api';

import ImageWithFallback from '~/components/shared/ImageWithFallback';

import findMax from '~/utils/findMax';
import CopyInput from './Components/CopyInput';
import { formatDateFromSeconds } from '~/utils/helper';
import {
  RecentlyViewedType,
  saveRecentlyViewed,
  FavoriteType,
  saveFavorite,
  removeFavoriteTour,
} from '~/services/clientApi';
import * as recentlyViewed from '~/store/recentlyViewed';
import * as auth from '~/store/auth';
import { getShortNumber } from '~/utils/getShortNumber';
import CustomDateRangePicker from '~/views/private/overview/Components/DateRangePicker';
import size from '~/utils/size';
import { defaultTimeRange } from '~/utils/defaultTimeRange';
import PointInfo from '~/views/private/overview/Components/PointInfo';
import TopPoint from '~/views/private/overview/Components/TopPoint';
import getTooltipValue from '~/utils/getTooltipValue';
import MultiDateRangePicker from './Components/MultiDateRangePicker';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { changeVisible } from '~/store/datePicker';
import { filterStatsByDate } from './utils';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    color: '#333',
    paddingLeft: '2.8em',
    paddingBottom: '0px',
  },
  backIcon: {
    marginRight: '14px',
    display: 'block',
    width: '25px',
    height: '24px',
    backgroundImage: 'url(/static/icons/arrow-back.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',
    cursor: 'pointer',

    '&:hover': {
      backgroundImage: 'url(/static/icons/arrow-back-active.svg)',
    },
  },
  infoCard: {
    cursor: 'pointer',
    '&:hover>div': {
      background: 'rgba(54, 166, 253, 0.15)',
    },
  },
  viewDurationSettersWrapper: {
    backgroundColor: 'white',
    position: 'relative',
    zIndex: 1,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 15%)',
    borderRadius: 6,
  },
  viewDurationSetter: {
    color: '#CBC9C9',
    cursor: 'pointer',
    fontSize: '0.75rem',
    padding: '3px 10px',
    borderRadius: 6,
    background: 'rgba(54, 166, 253, 0.05)',
    '&.active': {
      background: 'rgba(54, 166, 253, 0.15)',
      color: '#333',
    },
  },
  upload: {
    background: 'rgba(54, 166, 253, 0.15)',
    borderRadius: 6,
    padding: '3px 4px',
    width: '24px',
    display: 'flex',
    marginLeft: 6,
    height: '24px',
    cursor: 'pointer',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.4rem',
      height: '1.4rem',
      padding: '0.28rem',
    },
  },
  h12: {
    height: '370px',

    [theme.breakpoints.up('hiRes')]: {
      height: '12vw',
    },
  },
  h14: {
    height: '370px',

    [theme.breakpoints.up('hiRes')]: {
      height: '14.5vw',
    },
  },
  doughnutContainer: {
    '& > canvas': {
      margin: '0 auto',
      maxWidth: '541px',

      [theme.breakpoints.up('hiRes')]: {
        maxWidth: '25vw',
      },
    },
  },
  triangle: {
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '5px 0 5px 5px',
    borderColor: 'transparent transparent transparent #000000',

    [theme.breakpoints.up('hiRes')]: {
      borderWidth: '0.3rem 0 0.3rem 0.3rem',
    },
  },
  activeNumber: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    bottom: '4px',
    pointerEvents: 'none',
    height: '12px',
    color: '#36A6FD',
    fontSize: '0.625rem',

    [theme.breakpoints.up('hiRes')]: {
      bottom: '0.25rem',
      height: '0.75rem',
    },
  },
  smallTooltip: {
    borderRadius: '3px',
    transform: 'translate(-50%, -50%)',
    width: '50px',
    height: '30px',
    background: '#36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      borderRadius: '0.188rem',
      width: '3.125rem',
      height: '1.875rem',
    },
  },
  bigTooltip: {
    transform: 'translate(-50%, -100%)',
    borderRadius: '3px',
    width: '187px',
    paddingBottom: '20px',
    transition: 'opacity, left 0.1s ease',
    display: 'block',
    zIndex: 10,

    [theme.breakpoints.up('hiRes')]: {
      borderRadius: '0.188rem',
      width: '11.69rem',
      height: '10rem',
      paddingBottom: '1.25rem',
    },
  },
  smallTriangle: {
    position: 'absolute',
    top: '20px',
    borderLeft: '18px solid transparent',
    borderRight: '18px solid transparent',
    borderTop: '16px solid #36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      top: '1.25rem',
      borderLeft: '1.125rem solid transparent',
      borderRight: '1.125rem solid transparent',
      borderTop: '1rem solid #36A6FD',
    },
  },
  verySmallTriangle: {
    position: 'absolute',
    top: '-6px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #363636',
    left: '50%',
    transform: 'translateX(-50%)',
    pointerEvents: 'none',

    [theme.breakpoints.up('hiRes')]: {
      top: '-0.375rem',
      borderLeft: '0.625rem solid transparent',
      borderRight: '0.625rem solid transparent',
      borderBottom: '0.625rem solid #363636',
    },
  },
  bigTooltipPaper: {
    padding: '5px 5px 15px',
    pointerEvents: 'none',
    background: '#36A6FD',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.up('hiRes')]: {
      padding: '0.3rem 0.3rem 1rem',
    },
  },
  bigTriangle: {
    position: 'absolute',
    bottom: '5px',
    borderLeft: '18px solid transparent',
    borderRight: '18px solid transparent',
    borderTop: '16px solid #36A6FD',
    left: '50%',
    transform: 'translateX(-50%)',
    pointerEvents: 'none',

    [theme.breakpoints.up('hiRes')]: {
      top: '8.4375rem',
      borderLeft: '1.125rem solid transparent',
      borderRight: '1.125rem solid transparent',
      borderTop: '1rem solid #36A6FD',
    },
  },
  tooltipImg: {
    height: '70px',
    width: '100%',
    objectFit: 'cover',
    position: 'relative',

    [theme.breakpoints.up('hiRes')]: {
      height: '4.375rem',
    },
  },
  bigCircle: {
    width: '20px',
    height: '20px',
    background: 'rgb(54, 166, 253, 0.2)',
    top: '40px',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.25rem',
      height: '1.25rem',
      top: '2.5rem',
    },
  },
  mediumCircle: {
    width: '14px',
    height: '14px',
    background: 'rgb(54, 166, 253, 0.4)',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.875rem',
      height: '0.875rem',
    },
  },
  smallCircle: {
    width: '7px',
    height: '7px',
    background: '#36A6FD',

    [theme.breakpoints.up('hiRes')]: {
      width: '0.5rem',
      height: '0.5rem',
    },
  },
  topPointImg: {
    maxWidth: '200px',

    [theme.breakpoints.up('hiRes')]: {
      maxWidth: '12.5rem',
    },
  },
  value: {
    fontSize: '1.25rem',
    lineHeight: '2rem',

    [theme.breakpoints.up('lowRes')]: {
      fontSize: '1.5rem',
    },
  },
  tooltip: {
    tooltip: {
      margin: 10,
    },
  },
}));

const charts = {
  VISITS: 'Visits',
  VIEW_DURATION: 'View Duration',
  POINTS_VISITS: 'Point Visits',
};

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    margin: 10,
  },
}));

function InfoCard({ className = '', title, value, fullTitle }) {
  const classes = useStyles();
  const tooltipClass = useTooltipStyles();

  return (
    <Tooltip
      classes={tooltipClass}
      placement="top"
      title={`${fullTitle || title}: ${getTooltipValue(value)}`}
    >
      <div className={clsx('flex-auto mb-3', classes.infoCard, className)}>
        <div
          className="w-full bg-white flex flex-col justify-center rounded-lg"
          style={{
            padding: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          {title}
          <span className={`font-bold ${classes.value}`}>
            {getShortNumber(value)}
          </span>
        </div>
      </div>
    </Tooltip>
  );
}

const getPointDuration = pointStat => (pointStat ? pointStat.duration : 0);

const ctx = document.createElement('canvas').getContext('2d');
const gradient = ctx.createLinearGradient(0, 0, 0, 250);
gradient.addColorStop(0, 'rgba(54,166,253,0.6)');
gradient.addColorStop(1, 'rgba(54,166,253,0)');

const viewDurationGradient = ctx.createLinearGradient(0, 0, 0, 200);
viewDurationGradient.addColorStop(0, 'rgba(54,166,253,0.33)');
viewDurationGradient.addColorStop(1, 'rgba(54,166,253,0.165)');

const viewDurationGradientHover = ctx.createLinearGradient(0, 0, 0, 200);
viewDurationGradientHover.addColorStop(0, 'rgba(54,166,253,0.8)');
viewDurationGradientHover.addColorStop(1, 'rgba(54,166,253,0.4)');

const MultilocationOverviewView = () => {
  const dispatch = useDispatch();
  const refVisits = useRef();
  const refDuration = useRef();
  const refPointVisits = useRef();
  const refDoughnut = useRef();
  const [visitsDateValue, setVisitsDateValue] =
    React.useState(defaultTimeRange);

  const [viewDurationDateValue, setViewDurationDateValue] =
    React.useState(defaultTimeRange);

  const [isTitles, setIsTitles] = useState({});

  let { listId, companyName } = useParams();
  listId = decodeURIComponent(listId);
  companyName = decodeURIComponent(companyName);

  const classes = useStyles();
  const favorites = useSelector(state => state.auth.favorites);
  const [isImgTopPointLoad, setIsImgTopPointLoad] = useState(null);
  const [currentViewDurationEntity, setCurrentViewDurationEntity] =
    useState('location');
  const [uploadedImage, setUploadedImage] = useState();
  const navigate = useNavigate();

  const summaryStats = useAsync(async () => {
    dispatch(changeVisible(false));

    return getMultiLocationStats(
      listId,
      companyName,
      visitsDateValue[0].unix() * 1000,
      visitsDateValue[1].unix() * 1000,
    ).catch(() => ({
      totalVisitsMobile: 0,
      totalVisits: 1,
      totalCustomButtonClicks: 0,
      avgVisitsPerDay: 0,
      avgVisitsPerMonth: 0,
      totalDuration: 1,
      avgVisitDuration: 1,
    }));
  }, [listId, companyName, visitsDateValue]);
  const listLocations = useAsync(
    async () => getLocationsViaList(listId, companyName).catch(() => []),
    [listId, companyName],
  );
  const handleSaveViewDurationInPdf = useCallback(canvas => {
    const ratioOfList = 210 / 297;
    const maxWidth = 750;
    const maxHeight = 450;
    const imgDataFirst = canvas.toDataURL('image/png', 1);
    const heightFirst = canvas.height;
    const widthFirst = canvas.width;
    const ratioFirst = heightFirst / widthFirst;
    setCurrentViewDurationEntity(prev =>
      prev === 'locationPoints' ? 'location' : 'locationPoints',
    );
    setTimeout(() => {
      const imgDataSecond = canvas.toDataURL('image/png', 1);
      const heightSecond = canvas.height;
      const widthSecond = canvas.width;
      const ratioSecond = heightSecond / widthSecond;
      if (ratioFirst < ratioOfList / 2) {
        const docDefinition = {
          content: [
            {
              text:
                currentViewDurationEntity === 'location'
                  ? 'Tour View Duration'
                  : 'Point View Duration',
              style: 'header',
            },
            {
              text:
                currentViewDurationEntity === 'location'
                  ? '\nThe amount of time users spent on a tour divided by day for the specified period\n'
                  : '\nThe amount of time users spent on the tour divided by point by popularity of the tour point.\n',
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            {
              image: imgDataFirst,
              width: maxWidth,
              height: maxWidth * ratioFirst,
            },
            {
              text:
                currentViewDurationEntity === 'location'
                  ? 'Point View Duration'
                  : 'Tour View Duration',
              style: 'header',
            },
            {
              text:
                currentViewDurationEntity === 'location'
                  ? '\nThe amount of time users spent on the tour divided by point by popularity of the tour point.\n'
                  : '\nThe amount of time users spent on a tour divided by day for the specified period\n',
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },

            ratioSecond < (maxHeight - maxWidth * ratioFirst) / maxWidth
              ? {
                  image: imgDataSecond,
                  width: maxWidth,
                  height: maxWidth * ratioSecond,
                }
              : {
                  image: imgDataSecond,
                  width: (maxHeight - maxWidth * ratioFirst) / ratioSecond,
                  height: maxHeight - maxWidth * ratioFirst,
                },
          ],
          styles: {
            header: {
              fontSize: 23,
              bold: true,
            },
          },
          pageOrientation: 'landscape',
        };
        pdfMake.createPdf(docDefinition).download();
      } else if (ratioSecond < ratioOfList / 2) {
        const docDefinition = {
          content: [
            {
              text:
                currentViewDurationEntity === 'location'
                  ? 'Point View Duration'
                  : 'Tour View Duration',
              style: 'header',
            },
            {
              text:
                currentViewDurationEntity === 'location'
                  ? '\nThe amount of time users spent on the tour divided by point by popularity of the tour point.\n'
                  : '\nThe amount of time users spent on a tour divided by day for the specified period\n',
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            {
              image: imgDataSecond,
              width: maxWidth,
              height: maxWidth * ratioSecond,
            },
            {
              text:
                currentViewDurationEntity === 'location'
                  ? 'Tour View Duration'
                  : 'Point View Duration',
              style: 'header',
            },
            {
              text:
                currentViewDurationEntity === 'location'
                  ? '\nThe amount of time users spent on a tour divided by day for the specified period\n'
                  : '\nThe amount of time users spent on the tour divided by point by popularity of the tour point.\n',
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            ratioFirst < (maxHeight - maxWidth * ratioSecond) / maxWidth
              ? {
                  image: imgDataFirst,
                  width: maxWidth,
                  height: maxWidth * ratioFirst,
                }
              : {
                  image: imgDataFirst,
                  width: (maxHeight - maxWidth * ratioSecond) / ratioFirst,
                  height: maxHeight - maxWidth * ratioSecond,
                },
          ],
          styles: {
            header: {
              fontSize: 23,
              bold: true,
            },
          },
          pageOrientation: 'landscape',
        };
        pdfMake.createPdf(docDefinition).download();
      } else {
        const docDefinition = {
          content: [
            {
              text:
                currentViewDurationEntity === 'location'
                  ? 'Point View Duration'
                  : 'Tour View Duration',
              style: 'header',
            },
            {
              text:
                currentViewDurationEntity === 'location'
                  ? '\nThe amount of time users spent on the tour divided by point by popularity of the tour point.\n'
                  : '\nThe amount of time users spent on a tour divided by day for the specified period\n',
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            {
              image: imgDataSecond,
              width: (maxHeight / 2 - 30) / ratioSecond,
              height: maxHeight / 2 - 30,
            },
            {
              text:
                currentViewDurationEntity === 'location'
                  ? 'Tour View Duration'
                  : 'Point View Duration',
              style: 'header',
            },
            {
              text:
                currentViewDurationEntity === 'location'
                  ? '\nThe amount of time users spent on a tour divided by day for the specified period\n'
                  : '\nThe amount of time users spent on the tour divided by point by popularity of the tour point.\n',
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            {
              image: imgDataFirst,
              width: (maxHeight / 2 - 30) / ratioFirst,
              height: maxHeight / 2 - 30,
            },
          ],
          styles: {
            header: {
              fontSize: 23,
              bold: true,
            },
          },
          pageOrientation: 'landscape',
        };
        pdfMake.createPdf(docDefinition).download();
      }
      setCurrentViewDurationEntity(prev =>
        prev === 'locationPoints' ? 'location' : 'locationPoints',
      );
      setIsTitles({});
    }, 0);
  }, []);

  const handleSavePdf = canvas => {
    const imgData = canvas.toDataURL('image/png', 1);
    const maxWidth = 750;
    const maxHeight = 500;
    const ratioOfList = 210 / 297;
    const ratio = canvas.height / canvas.width;
    const docDefinition =
      ratio < ratioOfList
        ? {
            content: [
              {
                text: 'Radial Graph',
                style: 'header',
              },
              {
                text: "Information about the user's actions with the viewed tour",
                fontSize: 10,
                margin: [0, 0, 0, 10],
              },
              {
                image: imgData,
                width: maxWidth,
                height: maxWidth * ratio,
              },
            ],
            styles: {
              header: {
                fontSize: 23,
                bold: true,
              },
            },
            pageOrientation: 'landscape',
          }
        : {
            content: [
              {
                text: 'Radial Graph',
                style: 'header',
              },
              {
                text: "Information about the user's actions with the viewed tour",
                fontSize: 10,
                margin: [0, 0, 0, 10],
              },
              {
                image: imgData,
                width: maxHeight / ratio,
                height: maxHeight,
              },
            ],
            styles: {
              header: {
                fontSize: 23,
                bold: true,
              },
            },
            pageOrientation: 'landscape',
          };

    pdfMake.createPdf(docDefinition).download();
  };

  useEffect(() => {
    if (!isTitles.chart || isTitles.chart === charts.VIEW_DURATION) return;
    const { canvas, chart, text } = isTitles;
    setTimeout(() => {
      const imgData = canvas.toDataURL('image/png', 1);
      const maxWidth = 750;
      const maxHeight = 500;
      const ratioOfList = 210 / 297;
      const ratio = canvas.height / canvas.width;
      const docDefinition =
        ratio < ratioOfList
          ? {
              content: [
                {
                  text: chart,
                  style: 'header',
                },
                {
                  text: text,
                  fontSize: 10,
                  margin: [0, 0, 0, 10],
                },
                {
                  image: imgData,
                  width: maxWidth,
                  height: maxWidth * ratio,
                },
              ],
              styles: {
                header: {
                  fontSize: 35,
                  bold: true,
                },
              },
              pageOrientation: 'landscape',
            }
          : {
              content: [
                {
                  text: chart,
                  style: 'header',
                },
                {
                  text: text,
                  fontSize: 10,
                  margin: [0, 0, 0, 10],
                },
                {
                  image: imgData,
                  width: maxHeight / ratio,
                  height: maxHeight,
                },
              ],
              styles: {
                header: {
                  fontSize: 35,
                  bold: true,
                },
              },
              pageOrientation: 'landscape',
            };

      pdfMake.createPdf(docDefinition).download();
      setIsTitles({});
    }, 0);
  }, [isTitles]);

  useEffect(() => {
    if (isTitles.chart !== charts.VIEW_DURATION) return;
    const { canvas } = isTitles;
    handleSaveViewDurationInPdf(canvas);
  }, [handleSaveViewDurationInPdf, isTitles]);

  const mainLocation = useMemo(() => {
    if (listLocations.value) {
      return (
        listLocations.value.find(v => v.description.IS_MAIN) ||
        listLocations.value[0]
      );
    }
  }, [listLocations]);

  const [viewDurationChartTooltip, setViewDurationChartTooltip] =
    React.useState({
      caretX: 0,
      caretY: 0,
      scrollX: 0,
      show: false,
      value: 0,
      label: '',
    });

  const [visitChartTooltip, setVisitChartTooltip] = React.useState({
    caretX: 0,
    caretY: 0,
    scrollX: 0,
    show: false,
    value: 0,
    label: '',
  });

  const [clicksChartScaleWidth, setClicksChartScaleWidth] = React.useState(0);
  const [viewDurationChartScaleWidth, setViewDurationChartScaleWidth] =
    React.useState(0);

  const [clicksChartTooltip, setClicksChartTooltip] = React.useState({
    caretX: 0,
    caretY: 0,
    scrollX: 0,
    showLabel: false,
    caretLabelX: 0,
    caretLabelY: 0,
    show: false,
    value: 0,
    label: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
      setIsLoading(true);
      const { pointId, locationId } = viewDurationChartTooltip.value;

      const [existsInLocation] = await Promise.all([
        existsOnServer(getPointPreviewURL(pointId + 1, locationId)),
      ]);

      let newSrc;

      if (existsInLocation) {
        newSrc = getPointPreviewURL(pointId + 1, locationId);
      } else {
        const existsInStagingLocation = await existsOnServer(
          getStagingPointPreviewURL(pointId + 1, locationId),
        );

        if (existsInStagingLocation) {
          newSrc = getStagingPointPreviewURL(pointId + 1, locationId);
        } else {
          const cubePreviewUrl = getPointCubePreviewUrl(
            pointId + 1,
            locationId,
          );
          const existsCubePreview = await existsOnServer(cubePreviewUrl);

          if (existsCubePreview) {
            newSrc = cubePreviewUrl;
          }
        }
      }

      setUploadedImage(newSrc);
      setIsLoading(false);
    };

    fetchImage();
  }, [viewDurationChartTooltip.value]);

  const pointDurationStats = useAsync(async () => {
    if (listLocations.value) {
      return Promise.all(
        listLocations.value.map(location =>
          getStats(location.id, eventTypes.POINT_DURATION).then(stats =>
            stats.map(stat => ({
              ...stat,
              location: {
                id: location.id,
                name: location.name,
                previewImage: location.description.PREVIEW_IMAGE,
              },
            })),
          ),
        ),
      );
    }
    return Promise.resolve();
  }, [listLocations.value]);

  const visitStats = useAsync(async () => {
    if (listLocations.value) {
      const promises = listLocations.value.map(l =>
        getStats(l.id, eventTypes.VISIT),
      );

      const multiStats = await Promise.all(promises);
      const statMap = {};

      for (const locationStats of multiStats) {
        for (const { eventDate, eventsCount } of locationStats) {
          if (statMap[eventDate]) {
            statMap[eventDate] += eventsCount;
          } else {
            statMap[eventDate] = eventsCount;
          }
        }
      }

      return Object.entries(statMap).map(([eventDate, eventsCount]) => ({
        eventDate,
        eventsCount,
      }));
    }
  }, [listLocations.value]);

  const multitourViewDurationStats = useAsync(async () => {
    if (listId)
      return getMultitourViewDurationStats(listId, companyName).catch(() => []);
    return null;
  }, [listId]);
  const multitourPointsViewDurationStats = useAsync(async () => {
    if (listId)
      return getMultitourPointViewDurationStats(listId, companyName).catch(
        () => [],
      );
    return null;
  }, [listId]);
  const multitourVisitsStats = useAsync(async () => {
    if (listId)
      return getMultitourVisitsStats(listId, companyName).catch(() => []);
    return null;
  }, [listId]);
  const pointClicksStats = useAsync(async () => {
    if (listId)
      return getMultitourPointClicksStats(
        listId,
        companyName,
        visitsDateValue[0].unix() * 1000,
        visitsDateValue[1].unix() * 1000,
      ).catch(() => []);
    return null;
  }, [listId, visitsDateValue]);
  const multitourMenuClickStats = useAsync(async () => {
    if (listId)
      return getMultitourMenuClickStats(listId, companyName).catch(() => []);
    return null;
  }, [listId, companyName]);

  useEffect(() => {
    saveRecentlyViewed({ type: RecentlyViewedType.TOUR, listId })
      .then(res => dispatch(recentlyViewed.push(res)))
      .catch(console.error);
  }, [dispatch, listId]);

  const topLocationPoints = (pointDurationStats.value || []).map(xs =>
    findMax(xs, getPointDuration),
  );

  const multiTopPoint = findMax(topLocationPoints, getPointDuration);

  useEffect(() => {
    const getLocationDetails = async () => {
      if (!multiTopPoint || !multiTopPoint.location) {
        return;
      }

      multiTopPoint.location = await getLocation(multiTopPoint.location.id);
    };

    getLocationDetails();
  }, [multiTopPoint]);

  if (
    !multitourViewDurationStats ||
    !multitourViewDurationStats.value ||
    !multitourPointsViewDurationStats.value ||
    !multitourVisitsStats.value ||
    !pointClicksStats.value ||
    !multitourMenuClickStats.value ||
    !listLocations.value ||
    !visitStats.value
  ) {
    return <React.Fragment />;
  }

  if (!summaryStats.value || !pointDurationStats.value) {
    return <React.Fragment />;
  }

  const viewDurationStats = {
    location: filterStatsByDate(
      multitourViewDurationStats,
      viewDurationDateValue,
    )
      .map(item => {
        return {
          label: moment(item.eventDate).format('MM.DD'),
          data: item,
        };
      })
      .slice(),

    locationPoints: [...multitourPointsViewDurationStats.value]
      .sort((a, b) => b.duration - a.duration)
      .map(item => {
        return {
          label: `#${item.pointId + 1}`,
          data: item,
        };
      })
      .slice(0, 30)
      .reverse(),
    value: [],
  };

  const mobileVisitsPercent =
    (100 * summaryStats.value.totalVisitsMobile) /
    (summaryStats.value.totalVisitsMobile + summaryStats.value.totalVisits);

  const desktopVisitsPercent = 100 - mobileVisitsPercent;

  const filteredVisitStats = filterStatsByDate(
    visitStats,
    visitsDateValue,
  ).slice();

  if (filteredVisitStats.length === 1) {
    filteredVisitStats.push(filteredVisitStats[0]);
  }

  // const filteredViewDurationStats = [...viewDurationStats.value].sort(
  //   (a, b) => (b.eventDate > a.eventDate) - (b.eventDate < a.eventDate),
  // );

  const filteredPointClicksStats = [...pointClicksStats.value]
    .sort((a, b) => b.eventsCount - a.eventsCount)
    .slice(0, 30);

  const handleFavoriteClick = async () => {
    try {
      if (!favorites[listId]) {
        dispatch(auth.addFavorite(listId));
        await saveFavorite({ type: FavoriteType.TOUR, listId });
      } else {
        dispatch(auth.removeFavorite(listId));
        await removeFavoriteTour(listId);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const showClicksChartLabelTooltip = ({ point, left, top, caretX }) =>
    setClicksChartTooltip({
      ...clicksChartTooltip,
      value: point,
      label: `#${point.pointId + 1}`,
      showLabel: true,
      caretLabelX: left,
      caretLabelY: top,
      caretX,
    });

  const hideClicksChartLabelTooltip = () =>
    setClicksChartTooltip({ ...clicksChartTooltip, showLabel: false });

  const showViewDurationChartLabelTooltip = ({ point, left, top, caretX }) =>
    setViewDurationChartTooltip({
      ...viewDurationChartTooltip,
      value: point,
      label: `#${point.pointId + 1}`,
      showLabel: true,
      caretLabelX: left,
      caretLabelY: top,
      caretX,
    });

  const hideViewDurationChartLabelTooltip = () =>
    setViewDurationChartTooltip({
      ...viewDurationChartTooltip,
      showLabel: false,
    });

  return (
    <Page className={classes.root} title="Overview">
      <Header>
        <div
          style={{ display: 'flex', alignItems: 'center', color: '#6C6C6C' }}
        >
          <a onClick={() => navigate(-1)} className={classes.backIcon} />
          <div>
            <div style={{ fontSize: '1.5rem' }}>
              <span style={{ color: '#333' }}>{listId}</span>
            </div>
            <div style={{ fontWeight: 400, fontSize: '0.875rem' }}>
              Tour Analytics
            </div>
          </div>
        </div>
      </Header>
      <div className="w-full flex">
        <div
          className="w-7/12 flex gap-4"
          style={{ padding: '15px 0px 10px 0' }}
        >
          <p
            style={{
              fontSize: '12px',
              whiteSpace: 'nowrap',
              paddingTop: '5.5px',
            }}
          >
            Embed code
          </p>
          <CopyInput
            value={
              mainLocation &&
              `<iframe allowfullscreen title="view_player" src="${process.env.SNOWPACK_PUBLIC_PLAYER_URL}/?locationId=${mainLocation.id}" width="100%" height="500" />`
            }
          />
          <div>
            <a
              style={{
                paddingInline: '4.5px',
                paddingBlock: '3.5px 4px',
                borderRadius: '5px',
                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 4px',
                outline: 'none',
                border: '0px',
                textOverflow: 'ellipsis',
                fontSize: '0.7rem',
                whiteSpace: 'nowrap',
                background: 'white',
              }}
              href={`${process.env.SNOWPACK_PUBLIC_PLAYER_URL}/?locationId=${mainLocation.id}`}
            >
              Launch the tour
            </a>
          </div>
          <div style={{ paddingBlock: '.3rem' }}>
            <MultiDateRangePicker
              dateValueSetters={[
                [visitsDateValue, setVisitsDateValue],
                [viewDurationDateValue, setViewDurationDateValue],
              ]}
            />
          </div>
        </div>
      </div>
      <div
        className="w-full flex flex-wrap"
        style={{
          width: 'calc(100% + 8px)',
          marginLeft: '-4px',
        }}
      >
        <InfoCard
          className="px-1"
          title="Total Visits"
          value={
            (summaryStats.value.totalVisits || 0) +
            (summaryStats.value.totalVisitsMobile || 0)
          }
        />
        <InfoCard
          className="px-1"
          title="Avg. Visits Per Day"
          fullTitle="Average Visits Per Day"
          value={summaryStats.value.avgVisitsPerDay || 0}
        />
        <InfoCard
          className="px-1"
          title="Avg. Visits Per Month"
          fullTitle="Average Visits Per Month"
          value={Number(summaryStats.value.avgVisitsPerMonth || 0)}
        />
        <InfoCard
          className="px-1"
          title="Total Duration"
          value={
            formatDateFromSeconds(summaryStats.value.totalDuration) || '00:00'
          }
        />
        <InfoCard
          className="px-1"
          title="Avg. Visit Duration"
          fullTitle="Average Visit Duration"
          value={
            formatDateFromSeconds(summaryStats.value.avgVisitDuration || 0) ||
            '00:00'
          }
        />
        <InfoCard
          className="px-1"
          title="Custom Button Clicks"
          value={summaryStats.value.totalCustomButtonClicks}
        />
        <InfoCard
          className="px-1"
          title="Conversion"
          value={summaryStats.value.totalHotspotButtonClicks}
        />
      </div>

      <div
        className="w-full flex mt-1 mb-2"
        style={{ borderRadius: '10px', minHeight: '273px' }}
      >
        <div
          className="w-1/2 h-auto mr-1 bg-white relative"
          style={{
            padding: '0 20px 10px',
            borderRadius: '10px',
            // overflow: 'hidden',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            className="font-bold bg-white flex justify-between flex-wrap"
            style={{ paddingTop: 18 }}
          >
            <div
              className="flex"
              style={{
                marginBottom: 18,
                fontSize: '0.75rem',
                alignItems: 'center',
              }}
            >
              Visits
              <div
                className={classes.upload}
                onClick={() =>
                  setIsTitles({
                    canvas: refVisits.current.chartInstance.canvas,
                    chart: charts.VISITS,
                    text: '\nTotal number of visits for the specified period',
                  })
                }
              >
                <img src="/static/icons/upload.svg" alt="Upload" />
              </div>
            </div>
          </div>

          <div
            className={`w-full relative flex flex-col ${classes.h12}`}
            style={{
              overflow: 'auto',
            }}
          >
            <div
              style={{
                position: 'relative',
                height: '100%',
                minWidth: filteredVisitStats.length * size(16),
              }}
            >
              <Line
                ref={refVisits}
                data={{
                  datasets: [
                    {
                      backgroundColor: gradient,
                      lineTension: 0.01,
                      borderColor: '#36A6FD',
                      pointBackgroundColor: 'transparent',
                      pointBorderColor: 'transparent',
                      pointRadius: size(15),
                      pointHoverRadius: size(15),
                      borderWidth: 1,
                      parsing: false,
                      data: filteredVisitStats.map(item => item.eventsCount),
                      label: '',
                    },
                  ],
                  labels: filteredVisitStats.map(item =>
                    moment(item.eventDate).format('MM.DD'),
                  ),
                }}
                options={{
                  normalized: true,
                  animation: false,
                  cornerRadius: size(20),
                  layout: {
                    padding: {
                      right: size(13),
                    },
                  },
                  legend: { display: false },
                  maintainAspectRatio: false,
                  responsive: true,
                  devicePixelRatio: window.devicePixelRatio * 2,

                  hover: {
                    onHover(e, [chart]) {
                      if (!chart) {
                        setVisitChartTooltip({
                          ...visitChartTooltip,
                          show: false,
                        });
                        return;
                      }

                      return setVisitChartTooltip({
                        show: true,
                        scrollX:
                          e.target.parentElement.parentElement.scrollLeft,
                        offsetY: e.target.parentElement.parentElement.offsetTop,
                        caretX: chart._view.x,
                        caretY: chart._view.y,
                        value:
                          Math.round(
                            filteredVisitStats[chart._index].eventsCount * 10,
                          ) / 10,
                        label: chart._xScale.ticks[chart._index],
                      });
                    },
                  },
                  tooltips: {
                    enabled: false,
                    intersect: false,
                  },
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          fontSize: size(8),
                          reverse: true,
                        },
                        categoryPercentage: 0.97,
                        barPercentage: 0.97,
                        barThickness: 28,
                        scaleLabel: {
                          display: isTitles.chart === charts.VISITS,
                          labelString: 'Date',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        ticks: {
                          fontStyle: 'bold',
                          fontColor: '#000',
                          stepSize: 250,
                        },
                        scaleLabel: {
                          display: isTitles.chart === charts.VISITS,
                          labelString: 'Visits',
                        },
                      },
                    ],
                  },
                }}
              />
              <span
                className={`absolute bg-white font-bold ${classes.activeNumber}`}
                style={{
                  position: 'absolute',
                  opacity: visitChartTooltip.show ? 1 : 0,
                  left: visitChartTooltip.caretX,
                }}
              >
                {visitChartTooltip.label}
              </span>
            </div>
            <div
              style={{
                position: 'absolute',
                opacity: visitChartTooltip.show ? 1 : 0,
                left: visitChartTooltip.caretX,
                top: 0,
                width: '0px',
                borderLeft: '2px dashed rgba(255, 255, 255,1)',
                height: 'calc(100% - 20px)',
                background: '#36A6FD',
                pointerEvents: 'none',
              }}
            />
          </div>
          <div
            className={`absolute inset-0 font-bold text-xs text-white flex justify-center items-center ${classes.smallTooltip}`}
            style={{
              position: 'absolute',
              opacity: visitChartTooltip.show ? 1 : 0,
              left: visitChartTooltip.caretX - visitChartTooltip.scrollX + 20,
              top: visitChartTooltip.caretY + visitChartTooltip.offsetY - 2,
              transform: 'translate(-50%, -160%)',
              transition: 'opacity, left 0.1s ease',
              pointerEvents: 'none',
            }}
          >
            <div
              className={`absolute flex justify-center items-center rounded-full ${classes.bigCircle}`}
            >
              <div
                className={`flex justify-center items-center rounded-full ${classes.mediumCircle}`}
              >
                <div className={`rounded-full ${classes.smallCircle}`} />
              </div>
            </div>

            <div className={classes.smallTriangle} />
            <span className="relative">{visitChartTooltip.value}</span>
          </div>
        </div>

        <div
          className="w-1/2 h-auto ml-1 bg-white"
          style={{
            borderRadius: '10px',
            // overflow: 'hidden',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
            position: 'relative',
          }}
        >
          <div
            className={`mt-2 ${classes.upload}`}
            onClick={() =>
              handleSavePdf(refDoughnut.current.chartInstance.canvas)
            }
          >
            <img src="/static/icons/upload.svg" alt="Upload" />
          </div>
          <div
            className={`relative flex flex-col h-full ${classes.h12} ${classes.doughnutContainer}`}
            style={{
              top: '50%',
              transform: 'translate(0, -50%)',
            }}
          >
            <Doughnut
              ref={refDoughnut}
              data={{
                datasets: [
                  {
                    // fill:'start',
                    backgroundColor: multitourMenuClickStats.value.map(
                      x => x.color,
                    ),
                    // backgroundColor: colors.blue[500],
                    data: multitourMenuClickStats.value.map(x => x.count),
                    datalabels: {
                      labels: {
                        value: {},
                        name: {
                          align: 'end',
                          anchor: 'end',
                          offset: -1,
                          font: { size: 1 },
                          padding: 0,
                          backgroundColor: '#A0A0A0',
                          color: '#A0A0A0',
                          formatter() {
                            return '................................................................................................';
                          },
                          rotation(value) {
                            const sum = value.dataset.data.reduce(
                              (accumulator, currentValue) =>
                                accumulator + currentValue,
                            );
                            const position =
                              value.dataset.data
                                .slice(0, value.dataIndex + 1)
                                .reduce(
                                  (accumulator, currentValue) =>
                                    accumulator + currentValue,
                                ) -
                              value.dataset.data[value.dataIndex] / 2;
                            return (position / sum) * 360 - 90;
                          },
                        },
                      },
                    },
                  },
                ],
                labels: multitourMenuClickStats.value.map(x => x.name),
              }}
              options={{
                legend: {
                  display: true,
                  position: document.body.offsetWidth > 1500 ? 'left' : 'top',
                  labels: {
                    fontSize: size(12),
                  },
                },
                maintainAspectRatio: false,
                devicePixelRatio: window.devicePixelRatio * 2,
                layout: {
                  padding: 50,
                },
                scales: {
                  xAxes: [{ display: false }],
                  yAxes: [{ display: false }],
                },
                pieceLabel: {
                  render: 'label',
                  fontColor: '#000',
                  position: 'outside',
                },
                plugins: {
                  datalabels: {
                    anchor: 'end',
                    align: 'end',
                    offset: 22,
                    color: '#A0A0A0',
                    font: {
                      size: size(12),
                    },
                  },
                },
              }}
              plugins={[
                ChartDataLabels,
                {
                  beforeInit(chart, options) {
                    chart.legend.afterFit = function () {
                      this.height += 50;
                      this.legendHitBoxes.forEach(element => {
                        element.left = 0;
                      });
                    };
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div
        className="w-full flex mb-3"
        style={{ borderRadius: '10px', minHeight: '273px' }}
      >
        <div
          className="w-1/2 h-auto mr-1 bg-white relative flex flex-col"
          style={{
            padding: '0 20px 10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            className="flex justify-between flex-wrap"
            style={{ marginTop: 18 }}
          >
            <div style={{ marginBottom: 18 }} className="flex">
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                className={cn({
                  [classes.viewDurationSetter]: true,
                  active: currentViewDurationEntity === 'location',
                })}
                onClick={e => {
                  e.stopPropagation();
                  setCurrentViewDurationEntity('location');
                }}
              >
                Tour View Duration
              </span>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                className={cn({
                  [classes.viewDurationSetter]: true,
                  active: currentViewDurationEntity === 'locationPoints',
                })}
                onClick={e => {
                  e.stopPropagation();
                  setCurrentViewDurationEntity('locationPoints');
                }}
              >
                Point View Duration
              </span>
              <div
                className={classes.upload}
                onClick={() =>
                  setIsTitles({
                    canvas: refDuration.current.chartInstance.canvas,
                    chart: charts.VIEW_DURATION,
                  })
                }
              >
                <img src="/static/icons/upload.svg" alt="Upload" />
              </div>
            </div>
          </div>

          <div
            className={`relative ${classes.h14}`}
            style={{
              overflow: 'auto',
            }}
          >
            <div
              style={{
                position: 'relative',
                height: '100%',
                minWidth:
                  viewDurationStats[currentViewDurationEntity].length *
                  size(16),
              }}
            >
              <Bar
                ref={refDuration}
                data={{
                  labels: viewDurationStats[currentViewDurationEntity].map(
                    x => x.label,
                  ),
                  datasets: [
                    {
                      backgroundColor: viewDurationGradient,
                      hoverBackgroundColor: viewDurationGradientHover,
                      data: viewDurationStats[currentViewDurationEntity].map(
                        x => Math.round(x.data.duration / 60),
                      ),
                      label: '',
                      datasetLabel: '',
                    },
                  ],
                }}
                options={{
                  animation: false,
                  cornerRadius: 20,
                  layout: { padding: 0 },
                  legend: { display: false },
                  maintainAspectRatio: false,
                  responsive: true,
                  devicePixelRatio: window.devicePixelRatio * 2,

                  onHover(e, [chart]) {
                    if (!chart) {
                      setViewDurationChartTooltip({
                        ...viewDurationChartTooltip,
                        show: false,
                      });
                      return;
                    }

                    return setViewDurationChartTooltip({
                      show: true,
                      scrollX: e.target.parentElement.parentElement.scrollLeft,
                      offsetY: e.target.parentElement.parentElement.offsetTop,
                      caretX: chart._view.x,
                      caretY: chart._view.y,
                      value:
                        viewDurationStats[currentViewDurationEntity][
                          chart._index
                        ].data,
                      label: chart._xScale.ticks[chart._index],
                    });
                  },
                  tooltips: {
                    enabled: false,
                    intersect: false,
                  },
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          fontSize: size(8),
                          reverse: true,
                        },
                        categoryPercentage: 0.97,
                        barPercentage: 0.97,
                        barThickness: 14,
                        scaleLabel: {
                          display: isTitles.chart === charts.VIEW_DURATION,
                          labelString:
                            currentViewDurationEntity === 'location'
                              ? 'Date'
                              : 'Point',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        afterFit(scaleInstance) {
                          setViewDurationChartScaleWidth(scaleInstance.width);
                        },
                        ticks: {
                          fontStyle: 'bold',
                          fontColor: '#000',
                          stepSize: 250,
                        },
                        scaleLabel: {
                          display: isTitles.chart === charts.VIEW_DURATION,
                          labelString: 'Time (m)',
                        },
                      },
                    ],
                  },
                }}
              />
              <span
                className="absolute bg-white font-bold"
                style={{
                  opacity:
                    currentViewDurationEntity === 'locationPoints' &&
                    (viewDurationChartTooltip.show ||
                      viewDurationChartTooltip.showLabel)
                      ? 1
                      : 0,
                  left: viewDurationChartTooltip.caretX,
                  transform: 'translateX(-50%)',
                  bottom: '4px',
                  pointerEvents: 'none',
                  height: '12px',
                  color: '#36A6FD',
                  fontSize: '0.625rem',
                }}
              >
                {viewDurationChartTooltip.label}
              </span>

              <div
                className="flex absolute bottom-0"
                style={{
                  height: '22px',
                  width: `calc(100% - ${viewDurationChartScaleWidth}px)`,
                  marginLeft: viewDurationChartScaleWidth,
                }}
              >
                {viewDurationStats.locationPoints.map(({ data: point }) => {
                  return (
                    <span
                      key={`${point.locationId}${point.pointId}`}
                      className="flex-1 h-full"
                      onFocus={e =>
                        showViewDurationChartLabelTooltip({
                          point,
                          left:
                            e.target.getBoundingClientRect().left +
                            e.target.getBoundingClientRect().width / 2,
                          top:
                            e.target.getBoundingClientRect().top +
                            e.target.getBoundingClientRect().height,
                          caretX:
                            viewDurationChartScaleWidth +
                            Math.floor(
                              e.target.getBoundingClientRect().left -
                                e.target.parentElement.getBoundingClientRect()
                                  .left,
                            ) +
                            Math.floor(
                              e.target.getBoundingClientRect().width / 2,
                            ),
                        })
                      }
                      onBlur={hideViewDurationChartLabelTooltip}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className={`absolute inset-0 font-bold text-xs text-white flex justify-center items-center ${classes.smallTooltip}`}
            style={{
              position: 'absolute',
              opacity: viewDurationChartTooltip.show ? 1 : 0,
              left:
                viewDurationChartTooltip.caretX -
                viewDurationChartTooltip.scrollX +
                20,
              top: viewDurationChartTooltip.caretY + 35,
              transition: 'opacity, left 0.1s ease',
              visibility:
                currentViewDurationEntity === 'location' ? 'visible' : 'hidden',
            }}
          >
            <div className={classes.smallTriangle} />
            <span className="relative">
              {Math.round(viewDurationChartTooltip.value.duration / 60)}m
            </span>
          </div>

          <a
            href={`${process.env.PLAYER_URL}?locationId=${
              viewDurationChartTooltip.value.locationId
            }&point_id=${viewDurationChartTooltip.value.pointId + 1}`}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={() =>
              setViewDurationChartTooltip({
                ...viewDurationChartTooltip,
                show: true,
              })
            }
            onBlur={() =>
              setViewDurationChartTooltip({
                ...viewDurationChartTooltip,
                show: false,
              })
            }
            className={`absolute font-bold text-xs text-white flex justify-center items-center ${classes.bigTooltip}`}
            style={{
              // visibility: viewDurationChartTooltip.show ? 'visible' : 'hidden',
              left:
                viewDurationChartTooltip.caretX -
                viewDurationChartTooltip.scrollX +
                20,
              top:
                viewDurationChartTooltip.caretY +
                viewDurationChartTooltip.offsetY,
              visibility:
                viewDurationChartTooltip.show &&
                currentViewDurationEntity === 'locationPoints'
                  ? 'visible'
                  : 'hidden',
              // pointerEvents: 'click'
            }}
          >
            <div className={`w-full h-full ${classes.bigTooltipPaper}`}>
              <div className={classes.bigTriangle} />
              <div
                className="w-full flex justify-between font-bold pt-1"
                style={{ fontSize: '0.75rem' }}
              >
                <div>Point #{viewDurationChartTooltip.value.pointId + 1}</div>
                <div>
                  Duration:{' '}
                  {moment
                    .utc(viewDurationChartTooltip.value.duration * 1000)
                    .format('HH:mm:ss')}
                </div>
              </div>
              <div
                style={{
                  marginBottom: '5px',
                  margintop: '-3px',
                  fontSize: '0.5625rem',
                  fontWeight: '500',
                }}
              >
                {viewDurationChartTooltip.value
                  ? (
                      listLocations.value.find(
                        item =>
                          item.id === viewDurationChartTooltip.value.locationId,
                      ) || {}
                    ).name
                  : ''}
              </div>
              {isLoading && (
                <Skeleton
                  height={70}
                  style={{ position: 'relative', bottom: '4px' }}
                />
              )}
              {!isLoading && (
                <img
                  alt=""
                  className={classes.tooltipImg}
                  style={{
                    display: uploadedImage ? 'inline' : 'none',
                  }}
                  src={uploadedImage}
                />
              )}
            </div>
          </a>
        </div>

        <div
          className="w-1/2 h-auto ml-1 bg-white relative flex flex-col"
          style={{
            padding: '0 20px 10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            className="font-bold bg-white flex items-center"
            style={{ padding: '19px 0', fontSize: '0.75rem' }}
          >
            <div>Point Visits</div>
            <div
              className={classes.upload}
              onClick={() =>
                setIsTitles({
                  canvas: refPointVisits.current.chartInstance.canvas,
                  chart: charts.POINTS_VISITS,
                  text: '\nThe number of visits to the tour points divided by the number of point views',
                })
              }
            >
              <img src="/static/icons/upload.svg" alt="Upload" />
            </div>
          </div>
          <div
            className={`relative ${classes.h14}`}
            style={{ overflow: 'auto' }}
          >
            <div
              style={{
                position: 'relative',
                height: '100%',
                minWidth: filteredPointClicksStats.length * size(32),
              }}
            >
              <Bar
                ref={refPointVisits}
                data={{
                  labels: filteredPointClicksStats.map(
                    item => `#${item.pointId + 1}`,
                  ),
                  datasets: [
                    {
                      backgroundColor: viewDurationGradient,
                      hoverBackgroundColor: viewDurationGradientHover,
                      data: filteredPointClicksStats.map(
                        item => item.eventsCount,
                      ),
                      label: '',
                      datasetLabel: '',
                    },
                  ],
                }}
                options={{
                  animation: false,
                  cornerRadius: 20,
                  layout: { padding: 0 },
                  legend: { display: false },
                  maintainAspectRatio: false,
                  responsive: true,
                  devicePixelRatio: window.devicePixelRatio * 2,

                  onHover(e, [chart]) {
                    if (!chart) {
                      setClicksChartTooltip({
                        ...clicksChartTooltip,
                        show: false,
                      });
                      return;
                    }

                    return setClicksChartTooltip({
                      ...clicksChartTooltip,
                      show: true,
                      scrollX: e.target.parentElement.parentElement.scrollLeft,
                      offsetY: e.target.parentElement.parentElement.offsetTop,
                      caretX: chart._view.x,
                      caretY: chart._view.y,
                      value: filteredPointClicksStats[chart._index],
                      // center: chart._index < 14,
                      center: true,
                      label: chart._xScale.ticks[chart._index],
                    });
                  },
                  tooltips: {
                    enabled: false,
                    intersect: false,
                  },
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          fontSize: 8,
                        },
                        categoryPercentage: 0.97,
                        barPercentage: 0.97,
                        barThickness: 28,
                        scaleLabel: {
                          display: isTitles.chart === charts.POINTS_VISITS,
                          labelString: 'Point',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        afterFit(scaleInstance) {
                          setClicksChartScaleWidth(scaleInstance.width);
                        },
                        ticks: {
                          fontStyle: 'bold',
                          fontColor: '#000',
                          stepSize: 300,
                        },
                        scaleLabel: {
                          display: isTitles.chart === charts.POINTS_VISITS,
                          labelString: 'Clicks',
                        },
                      },
                    ],
                  },
                }}
              />
              <span
                className={`absolute bg-white font-bold ${classes.activeNumber}`}
                style={{
                  height: '12px',
                  bottom: '4px',
                  opacity:
                    clicksChartTooltip.show || clicksChartTooltip.showLabel
                      ? 1
                      : 0,
                  left: clicksChartTooltip.caretX,
                }}
              >
                {clicksChartTooltip.label}
              </span>
              <div
                className="flex absolute bottom-0"
                style={{
                  height: '22px',
                  width: `calc(100% - ${clicksChartScaleWidth}px)`,
                  marginLeft: clicksChartScaleWidth,
                }}
              >
                {filteredPointClicksStats.map(point => {
                  return (
                    <span
                      key={`${point.locationId}${point.pointId}`}
                      className="flex-1 h-full"
                      onFocus={e =>
                        showClicksChartLabelTooltip({
                          point,
                          left:
                            e.target.getBoundingClientRect().left +
                            e.target.getBoundingClientRect().width / 2,
                          top:
                            e.target.getBoundingClientRect().top +
                            e.target.getBoundingClientRect().height,
                          caretX:
                            clicksChartScaleWidth +
                            Math.floor(
                              e.target.getBoundingClientRect().left -
                                e.target.parentElement.getBoundingClientRect()
                                  .left,
                            ) +
                            Math.floor(
                              e.target.getBoundingClientRect().width / 2,
                            ),
                          // left:
                          //   42 + e.target.getBoundingClientRect().left -
                          //   e.target.parentElement.getBoundingClientRect().left,
                          // scrollX:
                          //   e.target.parentElement.parentElement.parentElement
                          //     .scrollLeft,
                        })
                      }
                      onBlur={hideClicksChartLabelTooltip}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          <PointInfo
            locationId={clicksChartTooltip.value.locationId}
            tooltipInfo={clicksChartTooltip}
            setTooltipInfo={setClicksChartTooltip}
            pointId={clicksChartTooltip.value.pointId}
            parentId={
              clicksChartTooltip.value &&
              listLocations.value.some(
                item => item.id === clicksChartTooltip.value.locationId,
              )
                ? listLocations.value.find(
                    item => item.id === clicksChartTooltip.value.locationId,
                  ).description.PARENT_VARIANT
                : ''
            }
            name={
              clicksChartTooltip.value
                ? (
                    listLocations.value.find(
                      item => item.id === clicksChartTooltip.value.locationId,
                    ) || {}
                  ).name
                : ''
            }
          />
        </div>

        <div
          style={{
            visibility: clicksChartTooltip.showLabel ? 'visible' : 'hidden',
            left: clicksChartTooltip.caretLabelX,
            top:
              clicksChartTooltip.caretLabelY +
              Number(
                getComputedStyle(document.documentElement).fontSize.slice(
                  0,
                  -2,
                ),
              ) /
                2,
            // left: clicksChartTooltip.caretLabelX - clicksChartTooltip.scrollX,
            // top: '328px',
            background: '#363636',
            color: 'white',
            // width: '150px',
            display: 'block',
            // alignSelf: 'flex-start',
            position: 'absolute',
            borderRadius: '3px',
            padding: '3px 5px',
            fontSize: '0.5625rem',
            pointerEvents: 'none',
            transform: 'translate(-50%, 0%)',
            whiteSpace: 'nowrap',
          }}
        >
          <div className={classes.verySmallTriangle} />
          <span style={{ fontWeight: 'bold' }}>
            #{clicksChartTooltip.value.pointId + 1}
          </span>{' '}
          {clicksChartTooltip.value
            ? (
                listLocations.value.find(
                  item => item.id === clicksChartTooltip.value.locationId,
                ) || {}
              ).name
            : ''}
        </div>

        <div
          style={{
            visibility:
              currentViewDurationEntity === 'locationPoints' &&
              viewDurationChartTooltip.showLabel
                ? 'visible'
                : 'hidden',
            left: viewDurationChartTooltip.caretLabelX,
            top: viewDurationChartTooltip.caretLabelY,
            background: '#363636',
            color: 'white',
            // width: '150px',
            display: 'block',
            // alignSelf: 'flex-start',
            position: 'absolute',
            borderRadius: '3px',
            padding: '3px 5px',
            fontSize: '0.5625rem',
            pointerEvents: 'none',
            transform: 'translate(-50%, 0%)',
            whiteSpace: 'nowrap',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '-6px',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderBottom: '10px solid #363636',
              left: '50%',
              transform: 'translateX(-50%)',
              pointerEvents: 'none',
            }}
          />
          <span style={{ fontWeight: 'bold' }}>
            #{viewDurationChartTooltip.value.pointId + 1}
          </span>{' '}
          {viewDurationChartTooltip.value
            ? (
                listLocations.value.find(
                  item => item.id === viewDurationChartTooltip.value.locationId,
                ) || {}
              ).name
            : ''}
        </div>
      </div>
      <div className="w-full flex">
        {multiTopPoint && multiTopPoint.location.description && (
          <TopPoint
            locationId={multiTopPoint.location.id}
            parentId={multiTopPoint.location.description.PARENT_VARIANT}
            topPointId={multiTopPoint.pointId}
          />
        )}
        <div className={multiTopPoint ? 'pl-4 w-2/3' : 'w-full'}>
          <ViewsByDevices
            mobilePercent={mobileVisitsPercent}
            desktopPercent={desktopVisitsPercent}
            mobileCount={summaryStats.value.totalVisitsMobile}
            desktopCount={summaryStats.value.totalVisits}
          />
        </div>
      </div>

      <div className="w-full flex mt-3">
        <Button
          color="primary"
          variant="contained"
          fullWidth
          size="large"
          type="button"
          onClick={handleFavoriteClick}
        >
          {favorites[listId] ? 'Remove from favorites' : 'Add to favorite'}
        </Button>
      </div>
    </Page>
  );
};

export default MultilocationOverviewView;
