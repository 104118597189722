import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import ImageWithFallback from '~/components/shared/ImageWithFallback';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '241px',
    background: '#fff',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    color: '#333',
    cursor: 'pointer',

    [theme.breakpoints.up('hiRes')]: {
      height: '16rem',
    },
  },
  number: {
    letterSpacing: '0.13em',
    fontWeight: 'bold',
    fontSize: '0.875rem',
  },
  prop: {
    fontWeight: 400,
    color: '#616161',
    fontSize: '0.625rem',
  },
  image: {
    width: '100%',
    height: '164px',
    boxShadow: 'inset 0px 4px 10px rgba(0, 0, 0, 0.5)',
    objectFit: 'cover',

    [theme.breakpoints.up('hiRes')]: {
      height: '10.8rem',
    },
  },
  goToTourIcon: {
    width: '15px',
    height: '15px',
    cursor: 'pointer',
    backgroundImage: 'url(/static/icons/go.svg)',

    [theme.breakpoints.up('hiRes')]: {
      width: '1rem',
      height: '1rem',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },

    '&:hover': {
      backgroundImage: 'url(/static/icons/go-active.svg)',
    },
  },
  paper: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,

    [theme.breakpoints.up('hiRes')]: {
      padding: '0.65rem',
    },
  },
}));

const BASE_URL = process.env.SNOWPACK_PUBLIC_CDN_ENDPOINT;

function TourCard({ tour }) {
  // console.log({ tour });
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(null);

  const mainLocation =
    tour.locations.find(l => l.description.IS_MAIN) || tour.locations[0];
  const { stats } = tour;

  return (
    <>
      {!isLoad && (
        <div
          style={{
            height: '241px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
            justifyContent: 'space-between',
          }}
          onClick={() => {
            if (mainLocation.name !== tour.multilocationName)
              return navigate(
                `/overview/multi/${tour.companyName}/${mainLocation.description.LIST_ID}`,
              );
            return navigate(`/overview/location/${mainLocation.id}`);
          }}
          role="button"
        >
          <Skeleton height={164} />
          <Skeleton height={57} />
        </div>
      )}
      <div
        className={classes.root}
        onClick={() => {
          if (mainLocation.name !== tour.multilocationName)
            return navigate(
              `/overview/multi/${tour.companyName}/${mainLocation.description.LIST_ID}`,
            );
          return navigate(`/overview/location/${mainLocation.id}`);
        }}
        role="button"
        style={{
          display: isLoad ? 'flex' : 'none',
        }}
      >
        <ImageWithFallback
          className={classes.image}
          src={`${BASE_URL}/${mainLocation.description.PREVIEW_IMAGE}`}
          fallbackSrc={`/static/images/no-img-placeholder.png`}
          alt=""
          isLoad={isLoad}
          setIsLoad={setIsLoad}
        />
        {/* <img
        className={classes.image}
        src={`${BASE_URL}/${mainLocation.description.PREVIEW_IMAGE}`}
        alt=""
      /> */}
        <div className={classes.paper}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                fontSize: '0,875rem',
                fontWeight: 700,
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '92%',
              }}
            >
              {tour.multilocationName}
            </div>

            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
              href={`${process.env.PLAYER_URL}?locationId=${mainLocation.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.goToTourIcon}
              onClick={e => e.stopPropagation()}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div className={classes.number}>
                {stats.totalVisits + stats.totalVisitsMobile}
              </div>
              <div className={classes.prop}>visits</div>
            </div>
            <div style={{ textAlign: 'right' }}>
              <div className={classes.number}>
                {stats.totalHotspotButtonClicks}
              </div>
              <div className={classes.prop}>conversions</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

TourCard.propTypes = {
  tour: PropTypes.object.isRequired,
};

export default TourCard;
