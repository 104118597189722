// const BASE_URL = process.env.API_ENDPOINT || '';
const BASE_URL = process.env.SNOWPACK_PUBLIC_API_ENDPOINT || '';

const SKIP_NAMES = Object.freeze({
  null: true,
  undefined: true,
});

function checkLocation({ name, list_id: listId }) {
  return (
    !SKIP_NAMES[name.toLowerCase()] &&
    /* !listId ||*/ !SKIP_NAMES[listId.toLowerCase()]
  );
}

function buildCompanyMultilocations(companyName, locationsMap) {
  const locations = (locationsMap[companyName] || []).filter(checkLocation);

  return locations.reduce((list, location) => {
    const name =
      location.list_id && location.list_id !== 'undefined' // location.description.LIST_ID
        ? location.list_id // location.description.LIST_ID
        : location.name;

    if (list.some(x => x.multilocationName === name)) {
      const idx = list.findIndex(x => x.multilocationName === name);

      list[idx].locations.push({ ...location, companyName });
    } else {
      list.push({
        companyName,
        multilocationName: name,
        id: location.id,
        locations: [location],
      });
    }

    return list;
  }, []);
}

export const buildLocationsForAdmin = async function () {
  return fetch(`${BASE_URL}/company-locations`)
    .then(r => r.json())
    .then(r => r.result);
};

export const buildLocationsForUser = async function (companyName) {
  companyName = companyName || 'google';
  return buildCompanyMultilocations(companyName, {
    [companyName]: await getAllLocations(companyName),
  });
};

export const getCompanyFullStats = async function (companyName) {
  const company = await buildLocationsForUser(companyName);

  const result = [];

  for (const multitour of company) {
    if (
      multitour.locations.length === 1 &&
      multitour.locations[0].name === multitour.multilocationName
    ) {
      result.push({
        ...multitour,
        isRealMultitour: false,
        stats: await getSummaryStats(multitour.id),
        mainLocation: multitour.locations[0],
      });
      continue;
    }

    result.push({
      ...multitour,
      isRealMultitour: true,
      stats: await getMultiLocationStats(
        multitour.multilocationName,
        companyName,
      ),
      mainLocation:
        multitour.locations.find(l => l.description.IS_MAIN) ||
        multitour.locations[0],
    });
  }

  return [...result].sort((a, b) => {
    if (
      a.stats.totalVisits + a.stats.totalVisitsMobile <
      b.stats.totalVisits + b.stats.totalVisitsMobile
    ) {
      return 1;
    }
    return -1;
  });
};

export const getCompaniesFullStats = async function () {
  const result = await fetch(`${BASE_URL}/company/full-stats`)
    .then(r => r.json())
    .then(r => r.result);

  return [...result].sort((a, b) => {
    if (
      a.stats.totalVisits + a.stats.totalVisitsMobile <
      b.stats.totalVisits + b.stats.totalVisitsMobile
    ) {
      return 1;
    }
    return -1;
  });
};

export async function getAllLocations(companyName = 'Google') {
  const res = await fetch(
    `${BASE_URL}/getLocationsOfCompany?companyName=${encodeURIComponent(
      companyName,
    )}`,
  );
  if (!res.ok) {
    throw new Error('getAllLocations request error');
  }
  const { result } = await res.json();
  return result.filter(checkLocation).map(location => {
    return {
      ...location,
      company_name: companyName,
    };
  });
}

export async function getAllLocationsNonStrict(companyName = 'Google') {
  const res = await fetch(
    `${BASE_URL}/getLocationsOfCompany?companyName=${encodeURIComponent(
      companyName,
    )}`,
  );
  if (!res.ok) {
    throw new Error('getAllLocations request error');
  }
  const { result } = await res.json();
  return result.map(location => {
    return {
      ...location,
      company_name: companyName,
    };
  });
}

function checkMultilocation(listId) {
  return !!listId && !SKIP_NAMES[listId.toLowerCase()];
}

export async function getAllMultiLocations() {
  const res = await fetch(`${BASE_URL}/multiLocations`);
  if (!res.ok) {
    throw new Error('getAllMultiLocations request error');
  }
  const { result } = await res.json();

  return result.map(entry => {
    return {
      ...entry,
      multilocations: entry.multilocations.filter(checkMultilocation),
    };
  });
}

export async function getAllStats() {
  const res = await fetch(`${BASE_URL}/stats/all`);

  if (!res.ok) {
    throw new Error('getAllStats request error');
  }
  const { result } = await res.json();

  return result;
}

export async function getLocation(id) {
  const res = await fetch(`${BASE_URL}/getLocationById?locationId=${id}`);
  const body = await res.json();
  if (!res.ok || body.result.length === undefined) {
    throw new Error('getLocation request error');
  }
  return body.result[0];
}

export async function getCompanyLocationStats(companyName) {
  const res = await fetch(
    `${BASE_URL}/stats/companyLocation?companyName=${encodeURIComponent(
      companyName,
    )}`,
  );

  const body = await res.json();
  if (!res.ok || !body.result) {
    throw new Error('getCompanyLocationStats request error');
  }

  return body.result;
}

export async function getCompanyLocations(companyName) {
  const res = await fetch(
    `${BASE_URL}/company/locations?companyName=${encodeURIComponent(
      companyName,
    )}`,
  );

  const body = await res.json();
  if (!res.ok || !body.result) {
    throw new Error('getCompanyLocations request error');
  }

  return body.result.filter(checkMultilocation);
}

export async function getMultiLocationStats(
  listId,
  companyName = '',
  dateStart,
  dateEnd,
) {
  const res = await fetch(
    `${BASE_URL}/stats/multiLocation?listId=${encodeURIComponent(
      listId,
    )}&companyName=${encodeURIComponent(
      companyName,
    )}&dateStart=${dateStart}&dateEnd=${dateEnd}`,
  );

  const body = await res.json();
  if (!res.ok || !body.result) {
    throw new Error('getMultiLocationStats request error');
  }

  return body.result;
}

export async function getStats(id, type, multitour) {
  const from = 0;
  const to = 2608829740392;
  const res = await fetch(
    `${BASE_URL}/getStat?locationId=${id}&statId=${type}&dateStart=${from}&dateEnd=${to}&multiTourName=${encodeURIComponent(
      multitour,
    )}`,
  );
  const body = await res.json();
  if (!res.ok || !body.result || body.result.message === undefined) {
    throw new Error('getStats request error');
  }
  return body.result.message;
}

export async function getSummaryStats(id, dateStart, dateEnd) {
  const res = await fetch(
    `${BASE_URL}/getLocationStats?locationId=${id}&dateStart=${dateStart}&dateEnd=${dateEnd}`,
  );
  const body = await res.json();
  if (!res.ok || !body.result || body.result.message === undefined) {
    throw new Error('getSummaryStats request error');
  }

  return body.result.message;
}

export async function getLocationsViaList(listId, companyName) {
  const res = await fetch(
    `${BASE_URL}/locations/list?listId=${encodeURIComponent(listId)}`,
  );
  const body = await res.json();
  if (!res.ok || !body.result) {
    throw new Error('getLocationsViaList request error');
  }

  return body.result
    .filter(checkLocation)
    .filter(l => l.company_name.toLowerCase() === companyName.toLowerCase());
}

async function tryFetch(route, options) {
  const res = await fetch(BASE_URL + route, options);
  if (!res.ok) {
    throw new Error(res.statusText);
  }
  return res.json();
}

export async function loginCompanyByToken(token) {
  const req = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Access-Token': `Bearer ${token}`,
    },
    body: JSON.stringify({}),
  };

  try {
    const data = await tryFetch('/login/client', req);
    return data.result;
  } catch (err) {
    console.error('Failed to log in', err);
    return null;
  }
}

export async function getLocationViewDurationStats(locationId) {
  const res = await fetch(
    `${BASE_URL}/getStat?statId=5&locationId=${locationId}&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  if (!res.ok) {
    throw new Error('getLocationViewDurationStats request error');
  }
  const { result } = await res.json();

  return result.message;
}

export async function getLocationPointViewDurationStats(locationId) {
  const res = await fetch(
    `${BASE_URL}/getStat?statId=6&locationId=${locationId}&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  if (!res.ok) {
    throw new Error('getLocationPointViewDurationStats request error');
  }
  const { result } = await res.json();

  return result.message;
}

export async function getLocationPointClicksStats(
  locationId,
  dateStart,
  dateEnd,
) {
  const res = await fetch(
    `${BASE_URL}/getStat?statId=0&locationId=${locationId}&dateStart=${dateStart}&dateEnd=${dateEnd}`,
  );

  if (!res.ok) {
    throw new Error('getLocationPointClicksStats request error');
  }
  const { result } = await res.json();

  return result.message;
}

export async function getLocationVisitsStats(locationId, multiTourName) {
  const locationStat = await fetch(
    `${BASE_URL}/getStat?statId=3&locationId=${locationId}&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  const locationMobileStat = await fetch(
    `${BASE_URL}/getStat?statId=8&locationId=${locationId}&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  let multiLocationStat;
  let multiLocationMobileStat;
  const isMultilocation =
    multiTourName !== undefined && multiTourName.toString().length > 0;

  if (isMultilocation) {
    multiLocationStat = await fetch(
      `${BASE_URL}/getStat?statId=4&locationId=${locationId}&multiTourName=${encodeURIComponent(
        multiTourName,
      )}&dateStart=${0}&dateEnd=${Date.now()}`,
    );

    multiLocationMobileStat = await fetch(
      `${BASE_URL}/getStat?statId=12&locationId=${locationId}&multiTourName=${encodeURIComponent(
        multiTourName,
      )}&dateStart=${0}&dateEnd=${Date.now()}`,
    );
  } else {
    multiLocationStat = { result: { message: [] }, status: 200 };
    multiLocationMobileStat = { result: { message: [] }, status: 200 };
  }

  // if (!desktopStatsRes.ok) {
  // throw new Error('getLocationVisitsStats request error');
  // }
  // const { result: locationStatJSON } = await desktopStatsRes.json();
  // const { result: locationMobileStatJSON } = await mobileStatsRes.json();
  // const { result: multiLocationStat } = await mobileStatsRes.json();
  // const { result: locationMobileStatJSON } = await mobileStatsRes.json();

  const [
    locationStatList,
    locationMobileStatList,
    multiLocationStatList,
    multiLocationMobileStatList,
  ] = await Promise.all([
    locationStat.json(),
    locationMobileStat.json(),
    isMultilocation ? multiLocationStat.json() : multiLocationStat,
    isMultilocation ? multiLocationMobileStat.json() : multiLocationMobileStat,
  ]).then(jsons => jsons.map(x => x.result.message));

  return Object.entries(
    [
      ...locationStatList,
      ...locationMobileStatList,
      ...multiLocationStatList.filter(l => l.locationId === Number(locationId)),
      ...multiLocationMobileStatList.filter(
        l => l.locationId === Number(locationId),
      ),
    ].reduce(
      (acc, { eventDate, eventsCount }) => ({
        ...acc,
        [eventDate]: (acc[eventDate] || 0) + eventsCount,
      }),
      {},
    ),
  ).map(([eventDate, eventsCount]) => ({ eventDate, eventsCount }));
}

export async function getLocationMenuClickStats(locationId) {
  const menuItems = {
    0: 'Minimap',
    1: 'Fullscreen',
    2: 'Options toggle button',
    3: 'Share',
    4: 'VR',
    5: 'Screenshot',
    6: 'Measure',
    7: 'Dollhouse',
    8: 'Help',
    9: 'Settings',
  };

  const menuColors = {
    0: '#ccc',
    1: '#9796F0',
    2: '#cc2964',
    3: '#51DCFF',
    4: '#630EEE',
    5: '#FFE259',
    6: '#fa2',
    7: '#29CC97',
    8: '#29a5cc',
    9: '#FF9212',
  };

  const res = await fetch(
    `${BASE_URL}/getStat?statId=1&locationId=${locationId}&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  if (!res.ok) {
    throw new Error('getLocationMenuClickStats request error');
  }
  const { result } = await res.json();

  // return result.message.reduce((acc, {itemId, eventsCount}) => ({...acc, [menuItems[itemId]]: {count: eventsCount, color: menuColors[itemId]}}), {});
  return result.message
    .filter(({ itemId }) => ![0].includes(itemId))
    .map(({ itemId, eventsCount }) => ({
      name: menuItems[itemId],
      count: eventsCount,
      color: menuColors[itemId],
    }));
}

export async function getMultitourViewDurationStats(multitour, companyName) {
  const res = await fetch(
    `${BASE_URL}/getMultilocationStat?statId=5&listId=${encodeURIComponent(
      multitour,
    )}&companyName=${encodeURIComponent(
      companyName,
    )}&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  if (!res.ok) {
    throw new Error('getMultitourViewDurationStats request error');
  }
  const { result } = await res.json();

  return result.message;
}

export async function getMultitourPointViewDurationStats(
  multitour,
  companyName,
) {
  const res = await fetch(
    `${BASE_URL}/getMultilocationStat?statId=6&listId=${encodeURIComponent(
      multitour,
    )}&companyName=${encodeURIComponent(
      companyName,
    )}&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  if (!res.ok) {
    throw new Error('getMultitourPointViewDurationStats request error');
  }
  const { result } = await res.json();

  return Object.entries(
    result.message.reduce(
      (acc, { locationId, pointId, duration }) => ({
        ...acc,
        [locationId]: {
          ...acc[locationId],
          [pointId]: ((acc[locationId] || [])[pointId] || 0) + duration,
        },
      }),
      {},
    ),
  ).reduce((acc, [locationId, points]) => {
    return [
      ...acc,
      ...Object.entries(points).map(([pointId, duration]) => ({
        locationId: parseInt(locationId, 10),
        pointId: parseInt(pointId, 10),
        duration,
      })),
    ];
  }, []);
}

export async function getMultitourPointClicksStats(
  multitour,
  companyName,
  dateStart,
  dateEnd,
) {
  const res = await fetch(
    `${BASE_URL}/getMultilocationStat?statId=0&listId=${encodeURIComponent(
      multitour,
    )}&companyName=${encodeURIComponent(
      companyName,
    )}&dateStart=${dateStart}&dateEnd=${dateEnd}`,
  );

  if (!res.ok) {
    throw new Error('getMultitourPointClicksStats request error');
  }
  const { result } = await res.json();

  return Object.entries(
    result.message.reduce(
      (acc, { locationId, pointId, eventsCount }) => ({
        ...acc,
        [locationId]: {
          ...acc[locationId],
          [pointId]: ((acc[locationId] || [])[pointId] || 0) + eventsCount,
        },
      }),
      {},
    ),
  ).reduce((acc, [locationId, points]) => {
    return [
      ...acc,
      ...Object.entries(points).map(([pointId, eventsCount]) => ({
        locationId: parseInt(locationId),
        pointId: parseInt(pointId),
        eventsCount,
      })),
    ];
  }, []);
}

export async function getMultitourVisitsStats(multitour, companyName) {
  const statsRes = await fetch(
    `${BASE_URL}/getMultilocationStat?statId=3&listId=${encodeURIComponent(
      multitour,
    )}&companyName=${encodeURIComponent(
      companyName,
    )}&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  const mobileStatsRes = await fetch(
    `${BASE_URL}/getMultilocationStat?statId=8&listId=${encodeURIComponent(
      multitour,
    )}&companyName=${encodeURIComponent(
      companyName,
    )}&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  if (!statsRes.ok || !mobileStatsRes.ok) {
    throw new Error('getMultitourVisitsStats request error');
  }
  const statsJSON = await statsRes.json().then(x => x.result.message);
  const mobileStatsJSON = await mobileStatsRes
    .json()
    .then(x => x.result.message);

  return Object.entries(
    [...statsJSON, ...mobileStatsJSON].reduce(
      (acc, { eventDate, eventsCount }) => ({
        ...acc,
        [eventDate]: (acc[eventDate] || 0) + eventsCount,
      }),
      {},
    ),
  ).map(([eventDate, eventsCount]) => ({ eventDate, eventsCount }));
}

export async function getMultitourMenuClickStats(multitour, companyName = '') {
  const menuItems = {
    0: 'Minimap',
    1: 'Fullscreen',
    2: 'Options toggle button',
    3: 'Share',
    4: 'VR',
    5: 'Screenshot',
    6: 'Measure',
    7: 'Dollhouse',
    8: 'Help',
    9: 'Settings',
  };

  const menuColors = {
    0: '#ccc',
    1: '#9796F0',
    2: '#cc2964',
    3: '#51DCFF',
    4: '#630EEE',
    5: '#FFE259',
    6: '#fa2',
    7: '#29CC97',
    8: '#29a5cc',
    9: '#FF9212',
  };

  const res = await fetch(
    `${BASE_URL}/getMultilocationStat?statId=1&listId=${encodeURIComponent(
      multitour,
    )}&companyName=${encodeURIComponent(
      companyName,
    )}&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  if (!res.ok) {
    throw new Error('getMultitourMenuClickStats request error');
  }
  const { result } = await res.json();

  // return result.message.reduce((acc, {itemId, eventsCount}) => ({...acc, [menuItems[itemId]]: {count: eventsCount, color: menuColors[itemId]}}), {});
  return result.message
    .filter(({ itemId }) => ![0].includes(itemId))
    .map(({ itemId, eventsCount }) => ({
      name: menuItems[itemId],
      count: eventsCount,
      color: menuColors[itemId],
    }));
}

export async function getCompanyVisitsStats(companyName) {
  const res = await fetch(
    `${BASE_URL}/getCompanyStat?statId=3&companyName=${encodeURIComponent(
      companyName,
    )}&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  if (!res.ok) {
    throw new Error('getCompanyVisitsStats request error');
  }
  const { result } = await res.json();

  return result.message;
}

export async function getAllVisitsStats() {
  const res = await fetch(
    `${BASE_URL}/getAllStat?statId=3&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  if (!res.ok) {
    throw new Error('getAllVisitsStats request error');
  }
  const { result } = await res.json();

  return result.message;
}

export async function getCompanyViewDurationStats(companyName) {
  const res = await fetch(
    `${BASE_URL}/getCompanyStat?statId=5&companyName=${encodeURIComponent(
      companyName,
    )}&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  if (!res.ok) {
    throw new Error('getCompanyViewDurationStats request error');
  }
  const { result } = await res.json();

  return result.message;
}

export async function getAllViewDurationStats() {
  const res = await fetch(
    `${BASE_URL}/getAllStat?statId=5&dateStart=${0}&dateEnd=${Date.now()}`,
  );

  if (!res.ok) {
    throw new Error('getAllViewDurationStats request error');
  }
  const { result } = await res.json();

  return result.message;
}

export const existsOnServer = async url => {
  try {
    const res = await fetch(url, { method: 'GET', mode: 'cors' });
    return res.status === 200;
  } catch (e) {
    return false;
  }
};
