export const eventTypes = {
  POINT: 0,
  MENU: 1,
  CUSTOM: 2,
  VISIT: 3,
  MULTITOUR: 4,
  POINT_DURATION: 6,
};

export const menuItems = {
  0: 'Minimap',
  1: 'Fullscreen',
  2: 'Options toggle button',
  3: 'Share',
  4: 'VR',
  5: 'Screenshot',
  6: 'Measure',
  7: 'Dollhouse',
  8: 'Help',
};
