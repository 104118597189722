import React, { useState, useMemo, useEffect } from 'react';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';
import { Box, Container, makeStyles } from '@material-ui/core';
import { getAllLocations } from '~/services/api';
import Page from '~/components/shared/Page';
import LocationTable from '~/components/shared/LocationTable';
import Toolbar from './components/Toolbar';
import { authRoles } from '~/constants';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const companies = [
  {
    id: 1,
    name: process.env.SNOWPACK_PUBLIC_TEST_1_COMPANY,
  },
];

function LocationListView() {
  const classes = useStyles();
  const user = useSelector(state => state.auth.user);
  const locations = useAsync(
    () =>
      getAllLocations(user.company).catch(e => {
        console.error('LocationListView:locations');
        return [];
      }),
    [user.company],
  );
  const [search, setSearch] = useState('');
  const [company, setCompany] = useState(0);

  const handleSearchInput = value => setSearch(value);
  const handleCompanySelect = value => setCompany(value);

  const isAdmin = user.role === authRoles.ADMIN;

  useEffect(() => {
    if (!isAdmin) {
      setCompany(user.company);
    }
  }, [isAdmin, user]);

  const filteredLocations = useMemo(
    () =>
      !locations.loading &&
      locations.value.filter(l => {
        const nameFilter = l.name.includes(search);
        const companyFilter = l.company_name === user.company;
        return nameFilter && companyFilter;
      }),
    [locations, search, user.company],
  );

  if (locations.loading) {
    return null;
  }

  return (
    <Page className={classes.root} title="Locations">
      <Container maxWidth={false}>
        <Toolbar
          search={search}
          company={company}
          companies={companies}
          onSearchInput={handleSearchInput}
          onCompanySelect={handleCompanySelect}
          showCompanySelect={isAdmin}
        />
        <Box mt={3}>
          <LocationTable locations={filteredLocations} />
        </Box>
      </Container>
    </Page>
  );
}

export default LocationListView;
