import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
// import { logout } from '~/services/magic';
import { useSelector } from 'react-redux';
import NavBar from './components/NavBar';
import SortBar from './components/SortBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: 'calc(100vh - 60px)',
    overflow: 'hidden',
    width: '100%',

    [theme.breakpoints.up('lowRes')]: {
      height: 'calc(100vh - 3.75rem)',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'auto',
    margin: '0 auto',
    padding: '0px 27px 27px 16px',
    animationName: '$blur',
    animationDuration: '500ms',
    animationFillMode: 'forwards',
    // paddingTop: 64,
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: 256,
    // },

    [theme.breakpoints.up('hiRes')]: {
      padding: '0px 47px 27px 36px',
    },
  },

  blurBack: {
    animationName: '$blurBack',
  },

  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    // overflow: 'hidden',
  },

  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'hidden',
  },

  '@keyframes blur': {
    '0%': {
      filter: 'none',
    },
    '100%': {
      filter: 'blur(1px)',
    },
  },

  '@keyframes blurBack': {
    '0%': {
      filter: 'blur(1px)',
    },
    '100%': {
      filter: 'none',
    },
  },
}));

function DashboardLayout() {
  const location = useLocation();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const visible = useSelector(state => state.datePicker.visible);
  const classes = useStyles();

  // const handleLogOut = async () => {
  //   try {
  //     await logout();
  //     document.location.assign(window.location.href);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const showSideBar = ['/gallery', '/overview/'].some(path =>
    location.pathname.startsWith(path),
  );

  return (
    <>
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.root}>
        {showSideBar && <SortBar />}
        <div
          className={`${classes.wrapper} ${visible ? '' : classes.blurBack}`}
        >
          {/* <header className={classes.header}></header> */}
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardLayout;
