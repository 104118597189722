import { createSlice } from '@reduxjs/toolkit';
import { FavoriteType } from '~/services/clientApi';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    favorites: {},
    user: {},
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;

      state.favorites = action.payload.favorites
        .filter(item => item.type === FavoriteType.TOUR)
        .reduce((acc, item) => ({ ...acc, [item.listId]: true }), {});
    },

    logout: state => {
      state.isLoggedIn = false;
      state.favorites = {};
      state.user = {};
    },

    addFavorite: (state, action) => {
      state.favorites[action.payload] = true;
    },

    removeFavorite: (state, action) => {
      delete state.favorites[action.payload];
    },
  },
});

export const { login, logout, addFavorite, removeFavorite } = authSlice.actions;

export default authSlice.reducer;
