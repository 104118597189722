import React, { useRef } from 'react';
import PropTypes from 'prop-types';

function ImageWithFallback(
  { src, fallbackSrc, isLoad, setIsLoad, style, ...props } = { isLoad: true },
) {
  const ref = useRef();

  const handleError = () => {
    if (ref.current && ref.current.src !== fallbackSrc) {
      ref.current.src = fallbackSrc;
    }
  };

  return (
    <img
      {...props}
      alt=""
      src={src}
      ref={ref}
      onError={handleError}
      onLoad={() => setIsLoad(true)}
      style={{
        display: isLoad ? 'inline' : 'none',
        objectFit: 'cover',
        ...style,
      }}
    />
  );
}

ImageWithFallback.propTypes = {
  src: PropTypes.string.isRequired,
  fallbackSrc: PropTypes.string.isRequired,
};

export default ImageWithFallback;
