import { createSlice } from '@reduxjs/toolkit';

const datePicker = createSlice({
  name: 'datePicker',
  initialState: {
    visible: false,
  },
  reducers: {
    changeVisible: (state, action) => {
      state.visible = action.payload;
    },
  },
});

export const { changeVisible } = datePicker.actions;

export default datePicker.reducer;
