import React, { useState } from 'react';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import moment from 'moment';
import { LocalizationProvider, DateRangePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { changeVisible } from '~/store/datePicker';

const useStyles = makeStyles(theme => ({
  dateInput: {
    fontSize: 12,
    background: '#36A6FD',
    color: 'white',
    borderRadius: 3,
    width: 90,
    padding: '0 4px',
    fontWeight: 'bold',

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '0.66rem',
    },

    '&::placeholder': {
      color: 'white',
      fontWeight: 'bold',
    },
  },
  label: {
    fontSize: 12,
    fontWeight: '400',
    color: '#6C6C6C',
    marginRight: 4,

    [theme.breakpoints.up('hiRes')]: {
      fontSize: '0.66rem',
    },
  },
  calendar: {
    marginLeft: 6,
    width: '20px',

    [theme.breakpoints.up('hiRes')]: {
      width: '1.25rem',
    },
  },
}));

function CustomDateRangePicker({ value, onChange }) {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const onClose = () => {
    dispatch(changeVisible(false));
    setVisible(false);
  };

  const onOpen = () => {
    dispatch(changeVisible(true));
    setVisible(true);
  };

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <DateRangePicker
        value={value}
        maxDate={moment()}
        onClose={onClose}
        open={visible}
        onOpen={onOpen}
        onChange={newValue => {
          if (!newValue[1]) return;
          onChange(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <div className="flex align-center">
            <span className={classes.label}>From</span>
            <input
              style={{ marginRight: 6 }}
              className={classes.dateInput}
              ref={startProps.inputRef}
              {...startProps.inputProps}
              value={moment(startProps.inputProps.value).format('MMM DD, YYYY')}
            />
            <span className={classes.label}>To</span>
            <input
              className={classes.dateInput}
              ref={endProps.inputRef}
              {...endProps.inputProps}
              value={moment(endProps.inputProps.value).format('MMM DD, YYYY')}
            />
            <div
              style={{ cursor: 'pointer', width: '20px' }}
              onClick={() => setVisible(!visible)}
            >
              <img
                src="/static/icons/calendar.svg"
                className={classes.calendar}
              />
            </div>
          </div>
        )}
      />
    </LocalizationProvider>
  );
}

export default CustomDateRangePicker;
