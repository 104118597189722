export default function toPercent(value) {
  if (
    typeof value !== 'number' ||
    Number.isNaN(value) ||
    !Number.isFinite(value)
  ) {
    return 0;
  }

  return Math.max(0, Math.min(100, value));
}
