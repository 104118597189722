import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ImageWithFallback from '~/components/shared/ImageWithFallback';
import getPointPreviewURL, {
  getPointCubePreviewUrl,
  getStagingPointPreviewURL,
} from '~/utils/getPointPreviewURL';
import { makeStyles } from '@material-ui/core';
import { existsOnServer } from '~/services/api';

const useStyles = makeStyles(theme => ({
  topPointImg: {
    maxWidth: '200px',

    [theme.breakpoints.up('hiRes')]: {
      maxWidth: '12.5rem',
    },
  },
}));

function TopPoint({ locationId, topPointId, parentId }) {
  const classes = useStyles();

  const [src, setSrc] = useState();
  const [loaded, setLoaded] = useState(false);

  const checkPreview = async () => {
    const [existsInLocation, existsInParentLocation] = await Promise.all([
      existsOnServer(getPointPreviewURL(topPointId + 1, locationId)),
      existsOnServer(getPointPreviewURL(topPointId + 1, parentId)),
    ]);

    if (existsInLocation) {
      return setSrc(getPointPreviewURL(topPointId + 1, locationId));
    }

    if (existsInParentLocation) {
      return setSrc(getPointPreviewURL(topPointId + 1, parentId));
    }

    const existsInStagingLocation = await existsOnServer(
      getStagingPointPreviewURL(topPointId + 1, locationId),
    );

    if (existsInStagingLocation) {
      return setSrc(getStagingPointPreviewURL(topPointId + 1, locationId));
    }

    const cubePreviewUrl = getPointCubePreviewUrl(topPointId + 1, locationId);
    const existsCubePreview = await existsOnServer(cubePreviewUrl);

    if (existsCubePreview) {
      setSrc(cubePreviewUrl);
    }
  };

  useEffect(() => {
    checkPreview();

    return () => {
      setSrc(undefined);
      setLoaded(false);
    };
  }, [locationId, parentId, topPointId]);

  return (
    <a
      href={`${process.env.PLAYER_URL}?locationId=${locationId}&point_id=${
        topPointId + 1
      }`}
      target="_blank"
      rel="noopener noreferrer"
      className="w-1/3 flex overflow-hidden"
      style={{
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
      }}
    >
      <div
        className="w-1/2 h-auto bg-white"
        style={{ padding: '10px', borderRadius: '10px', flexGrow: 1 }}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: '0.6875rem',
            color: '#6C6C6C',
          }}
        >
          Top Point
        </div>
        <div style={{ fontWeight: 700, fontSize: '1.125rem' }}>
          Point #{topPointId + 1}
        </div>
      </div>
      {!loaded && (
        <div className="w-1/2 h-full">
          <Skeleton
            height={110}
            style={{ position: 'relative', bottom: '4px' }}
          />
        </div>
      )}
      <ImageWithFallback
        style={{ borderRadius: '10px' }}
        className={`w-1/2 h-full object-cover ${classes.topPointImg}`}
        src={src}
        fallbackSrc={`/static/images/no-img-placeholder.png`}
        alt=""
        isLoad={loaded}
        setIsLoad={setLoaded}
      />
    </a>
  );
}

export default TopPoint;
