import React from 'react';
import CustomDateRangePicker from './DateRangePicker';
import { defaultTimeRange } from '~/utils/defaultTimeRange';

const MultiDateRangePicker = ({ dateValueSetters }) => {
  const handleDateChange = newDate => {
    dateValueSetters.forEach(([_, setDateValue]) => setDateValue(newDate));
  };

  return (
    <div>
      <CustomDateRangePicker
        value={dateValueSetters[0][0]}
        onChange={handleDateChange}
      />
    </div>
  );
};

export default MultiDateRangePicker;
