export default function findMax(xs = [], getValue = x => x) {
  let r;
  let n = 0;
  for (const x of xs) {
    const a = getValue(x);
    if (a > n) {
      r = x;
      n = a;
    }
  }
  return r;
}
